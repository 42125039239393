import { Skeleton } from "antd";
import Notiflix from "notiflix";
import React, { useContext, useEffect, useState } from "react";
import { Card, Col, Form, Offcanvas, Row } from "react-bootstrap";
import { useLocation, useParams } from "react-router-dom";
import GetApiCall from "../../API/GETAPI";
import PostApiCall from "../../API/POSTAPI";
import RestaurantCard from "../../component/RestaurantCard/RestaurantCard";
import GrubDigestSearchBar from "../../component/SearchBar/GrubDigestSearchBar";
import { store } from "../../Context/store";

function Restaurant() {
  let location = useLocation();
  let params = useParams();
  const [featuredRestaurant, setFeaturedRestaurant] = useState([]);
  const { chooseCity, setChooseCity } = useContext(store);
  const [searchValue, setSearchValue] = useState("");
  const [show, setShow] = useState(false);
  const [filter, SetFilter] = useState([]);
  const [cuisinesId, setCuisinesId] = useState([]);
  const [restaurantTypeId, setRestaurantTypeId] = useState([]);
  const [type, setType] = useState("");
  const [loading, setLoading] = useState(true);

  const handleClose = () => {
    setShow(false);
  };
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 0);
    if (chooseCity != "") {
      PostApiCall.postRequest(
        {
          //cityId: chooseCity,
          cityId: 131687,
        },
        "getRestaurantMasterData"
      ).then((results) =>
        results?.json().then((obj1) => {
          if (results.status == 200 || results.status == 201) {
            if (obj1.message.length != 0) {
              setFeaturedRestaurant(
                obj1.message.filter((data) => {
                  if (data.fld_active != "New") {
                    return data;
                  }
                })
              );
              setLoading(false);
            }
            setLoading(false);
            Notiflix.Loading.remove();
          }
        })
      );
    }
  }, [chooseCity]);
  const getCuisine = () => {
    GetApiCall.getRequest("getCuisines").then((results) =>
      results?.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          if (obj1.message.length != 0) {
            SetFilter(obj1.message);
          }
          Notiflix.Loading.remove();
        }
      })
    );
  };
  const getRestaurantType = () => {
    GetApiCall.getRequest("getrestauranttype").then((results) =>
      results?.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          if (obj1.message.length != 0) {
            SetFilter(obj1.message);
          }
          Notiflix.Loading.remove();
        }
      })
    );
  };
  const onSearch = RegExp(
    `.*${searchValue.toLowerCase().split("").join(".*")}.*`
  );
  const handleCusineChange = (event) => {
    const id = parseInt(event.target.value);

    if (event.target.checked) {
      if (type == "Cusines") {
        setCuisinesId((prevIds) => [...prevIds, id]);
      } else {
        setRestaurantTypeId((prevIds) => [...prevIds, id]);
      }
    } else {
      if (type == "Cusines") {
        setCuisinesId((prevIds) => prevIds.filter((prevId) => prevId !== id));
      } else {
        setRestaurantTypeId((prevIds) =>
          prevIds.filter((prevId) => prevId !== id)
        );
      }
    }
  };

  return (
    <section className="services pt-lg-3 pb-70">
      <div className="container-fluid px-lg-4 cuisines">
        <GrubDigestSearchBar />
        <div className="row">
          <div className="col-12 text-center mb-4">
            <h3 className="mb-2">Dine-in & Food Delivery Options</h3>
            <h6 className="mb-2">Search for the finest food experience</h6>
          </div>
        </div>
        {loading == true ? (
          <Row>
            <Col lg={3} md={6} xs={12} className="mb-3">
              <Skeleton.Image
                active={true}
                className="img-fluid w-100 rounded skeleteon-image-height mb-1"
              />
              <Skeleton active={true} block={false} className="mb-1" />
            </Col>
            <Col lg={3} md={6} xs={12} className="mb-3">
              <Skeleton.Image
                active={true}
                className="img-fluid w-100 rounded skeleteon-image-height mb-1"
              />
              <Skeleton active={true} block={false} className="mb-1" />
            </Col>
            <Col lg={3} md={6} xs={12} className="mb-3">
              <Skeleton.Image
                active={true}
                className="img-fluid w-100 rounded skeleteon-image-height mb-1"
              />
              <Skeleton active={true} block={false} className="mb-1" />
            </Col>
            <Col lg={3} md={6} xs={12} className="mb-3">
              <Skeleton.Image
                active={true}
                className="img-fluid w-100 rounded skeleteon-image-height mb-1"
              />
              <Skeleton active={true} block={false} className="mb-1" />
            </Col>
          </Row>
        ) : featuredRestaurant.length != 0 ? (
          <div className="row min-vh-50">
            {featuredRestaurant
              .filter((res) => {
                if (params.value == undefined) {
                  return res;
                } else if (
                  params.type == "cuisine" &&
                  res.cusines_list?.toLowerCase()?.includes(params.value.replace(/-/g, " ")?.toLowerCase())
                ) {
                  return res;
                }

                // console.log(String(res.restaurant_type_list).replace(/& /g,"").toLowerCase(), "compare")
                // console.log(params.value.replace(/-/g, " ").toLowerCase(),"value")
                // console.log(String(res.restaurant_type_list).replace(/& /g,"").toLowerCase().includes(params.value.replace(/-/g, " ").toLowerCase(),"value"))
                else if (
                  params.type == "restauranttype" &&
                  String(res.restaurant_type_list)
                    .replace(/& /g, "")?.toLowerCase()?.includes(params.value.replace(/-/g, " ").toLowerCase())
                ) {
                  return res;
                }
              })
              .map((data) => {
                return (
                  <>
                    {data ? (
                      <RestaurantCard featuredRestaurant={data} />
                    ) : (
                      <Col lg={12}>
                        <h5 className="text-center">
                          There is no restaurant available near you.
                        </h5>
                      </Col>
                    )}
                  </>
                );
              })}
          </div>
        ) : (
          <div className="row min-vh-50 justify-content-center align-items-center">
            <Col lg={12}>
              <h5 className="text-center">
                There is no restaurant available near you.
              </h5>
            </Col>
          </div>
        )}
      </div>
      <Offcanvas
        show={show}
        onHide={handleClose}
        // backdrop="static"
        placement="end"
        className="review-canvas"
      >
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{type}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <div>
            {filter.map((data) => {
              return (
                <Card className="mb-3 shadow-sm">
                  <Card.Body>
                    <div className="d-flex">
                      <img
                        src={data.fld_image}
                        className="review-profile-pic"
                      />
                      <div className="review-profile-info">
                        <p className="fw-bold mb-1">{data.fld_name}</p>
                      </div>
                      <Form.Check
                        value={data.fld_id}
                        onChange={(e) => {
                          handleCusineChange(e);
                        }}
                        checked={
                          type == "Cusines"
                            ? cuisinesId.includes(data.fld_id)
                            : restaurantTypeId.includes(data.fld_id)
                        }
                        type="checkbox"
                        className="facilities-selection"
                      />
                    </div>
                  </Card.Body>
                </Card>
              );
            })}
          </div>
        </Offcanvas.Body>
      </Offcanvas>
    </section>
  );
}

export default Restaurant;
