import { Select, Skeleton } from "antd";
import Notiflix from "notiflix";
import React, { useEffect, useState } from "react";
import { BiSearchAlt } from "react-icons/bi";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import { useNavigate } from "react-router-dom";
import GetApiCall from "../../API/GETAPI";
import PostApiCall from "../../API/POSTAPI";
import BgVideo from "../../assets/images/bg_video-1.mp4";
import BgImage from "../../assets/images/restauranthomebg.jpg";
import RestaurantCard from "../../component/RestaurantCard/RestaurantCard";
import { Col, Offcanvas, Row } from "react-bootstrap";
import { store } from "../../Context/store";
import { useContext } from "react";
const { Option } = Select;
function Home() {
  let navigate = useNavigate();
  let restaurantpath, cuisinepath;
  const [resType, setResType] = useState([]);
  const [cuisineType, setCuisineType] = useState([]);
  const [offerList, setOfferList] = useState([]);
  const [eventList, setEventList] = useState([]);
  const [featuredRestaurant, setFeaturedRestaurant] = useState([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchData, setSearchData] = useState([]);
  const [searchText, setSearchText] = useState("");
  const [showNavbar, setShowNavbar] = useState(false);
  const { setHomeVisited } = useContext(store);
  const handleNavbar = () => setShowNavbar(false);
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 0);
    setTimeout(() => {
      setHomeVisited(true);
    }, 5000);
    GetApiCall.getRequest("getSearchData").then((results) =>
      results?.json().then((obj1) => {
        if (results.status === 200 || results.status === 201) {
          if (obj1.message.length !== 0) {
            setSearchData(obj1.message);
          }
          Notiflix.Loading.remove();
        }
      })
    );
    GetApiCall.getRequest("getrestauranttype").then((results) =>
      results?.json().then((obj1) => {
        if (results.status === 200 || results.status === 201) {
          if (obj1.message.length !== 0) {
            setResType(obj1.message);
            localStorage.setItem(
              "RestaurantType",
              JSON.stringify(obj1.message)
            );
          }
          Notiflix.Loading.remove();
        }
      })
    );
    GetApiCall.getRequest("getCuisines").then((results) =>
      results?.json().then((obj1) => {
        if (results.status === 200 || results.status === 201) {
          if (obj1.message.length !== 0) {
            setCuisineType(obj1.message);
            localStorage.setItem("CuisineType", JSON.stringify(obj1.message));
          }
          Notiflix.Loading.remove();
        }
      })
    );
    GetApiCall.getRequest("getOfferList").then((results) =>
      results?.json().then((obj1) => {
        if (results.status === 200 || results.status === 201) {
          if (obj1.message.length !== 0) {
            setOfferList(obj1.message);
          }
          Notiflix.Loading.remove();
        }
      })
    );
    //if (chooseCity !== "") {
    PostApiCall.postRequest(
      {
        //cityId: chooseCity,
        cityId: 131687,
      },
      "getRestaurantMasterData"
    ).then((results) =>
      results?.json().then((obj1) => {
        if (results.status === 200 || results.status === 201) {
          if (obj1.message.length !== 0) {
            setFeaturedRestaurant(
              obj1.message.filter((data) => {
                if (data.fld_active != "New") {
                  return data;
                }
              })
            );
          }
          Notiflix.Loading.remove();
        }
      })
    );
    //}
    GetApiCall.getRequest("getEvents").then((results) =>
      results?.json().then((obj1) => {
        if (results.status === 200 || results.status === 201) {
          if (obj1.message.length !== 0) {
            setEventList(obj1.message);
          }
          Notiflix.Loading.remove();
        }
      })
    );
  }, []);

  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };
  const cuisinesresponsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 5,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 4,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 3,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 2,
    },
  };

  const onSearch = RegExp(
    `.*${searchValue.toLowerCase().split("").join(".*")}.*`
  );
  const filteredData = searchData.filter((dat) => {
    return (
      dat.fld_name.toLowerCase().includes(searchText.toLowerCase()) ||
      dat.fld_address.toLowerCase().includes(searchText.toLowerCase())
    );
  });
  return (
    <>
      <header className="hero-container-homebg">
        <div className="overflow-hidden h-100">
          <video
            src={BgVideo}
            autoPlay={true}
            poster={BgImage}
            muted={true}
            loop={true}
            playsInline={true}
            className="homepage-video h-100 w-100"
          />
        </div>
        <div className="py-5 justify-content-center align-items-center">
          <div className="col-lg-6 search-section-text">
            <h1 className="extra-text text-white text-center">
              We are all about <span>Food!</span>
            </h1>
            <h4 className="mb-lg-2 mb-2 text-white text-center">
              Unearthing the Foodie in you...!
            </h4>
          </div>
        </div>
        <div className="">
          <div className="col-lg-8 search-section">
            <div className="card shadow text-center p-3 w-100">
              <form>
                <div className="form-row position-relative">
                  <div className="col-lg-4 col-12">
                    <h4 className="extra-text mb-lg-0 mb-2 no-text-shadow pt-1">
                      Good <span className="no-text-shadow">Mood</span>, Find{" "}
                      <span className="no-text-shadow">Food</span>
                    </h4>
                  </div>
                  <div className="col-lg-8 col-12 search-input d-none d-md-block">
                    <input
                      value={searchText}
                      onChange={(e) => {
                        setSearchText(e.target.value);
                      }}
                      placeholder="Look for restaurants, location, cuisines or a dish"
                    />
                    <span className="search-icon">
                      <BiSearchAlt className="search-icon-svg" />
                    </span>
                  </div>
                  <div
                    className={
                      filteredData.length > 0 && searchText !== ""
                        ? "search-result-pc-show search-result-pc"
                        : "search-result-pc"
                    }>
                    {filteredData.length > 0 && searchText !== "" && (
                      <div>
                        {filteredData.map((data) => {
                          return (
                            <div
                              onClick={() => {
                                navigate(`${data.fld_route}`, {
                                  state: data.fld_client_id,
                                });
                              }}
                              className="d-flex search-result-card">
                              <img
                                src={data.fld_image}
                                alt="img"
                                className="dish-image"
                                loading="lazy"
                              />
                              <div>
                                <div
                                  className="review-profile-info fw-bold mb-1"
                                  style={{ marginLeft: "8px" }}>
                                  {data.fld_name}
                                </div>
                                <div
                                  style={{
                                    marginLeft: "8px",
                                    display:
                                      data.fld_address == null
                                        ? "none"
                                        : "block",
                                  }}>
                                  {data.fld_address}
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                    )}
                  </div>
                  <div
                    className="col-lg-8 col-12 search-input d-block d-md-none"
                    onClick={() => setShowNavbar(true)}>
                    <div className="search-input-text">
                      <span>
                        Look for restaurants, location, cuisines or a dish
                      </span>
                    </div>
                    <span className="search-icon">
                      <BiSearchAlt className="search-icon-svg" />
                    </span>
                  </div>
                </div>
              </form>
            </div>
          </div>
        </div>
      </header>
      <section className="mt-100 pb-0">
        <div className="container">
          <div className="row">
            <div className="col-lg-12 text-center mb-60">
              <h4 className="extra-text">
                Explore a <span>Collection</span> of Restaurants, Cafés, Pubs,
                Bars & Eateries
              </h4>
              <p className="px-lg-0 px-0 mx-auto about-des">
                We are a passionate foodie group that helps you find the right
                place to dine out or order food based on your cravings. Whether
                it's a special occasion or just a casual meal you're planning,
                you can discover the best possible eatery right here.
              </p>
            </div>
          </div>
        </div>
      </section>
      <section>
        <div className="feat pt-25 pb-25 hero rest-type" data-scroll-index="2">
          <div className="container-fluid px-lg-4 hero">
            <div className="row">
              <div className="section-head col-lg-3">
                <h4>
                  <span>Type of</span>
                  <br />
                  <span className="grub-red">Restaurants</span>
                </h4>
              </div>
              <div className="section-head col-lg-9 d-flex justify-content-lg-end align-items-end">
                <h4 className="extra-text title-right">
                  Find your favorite <span>food</span> joint
                </h4>
              </div>
            </div>
            <div className="row restaurant-type-wrap-main d-flex">
              {resType.length > 0 ? (
                resType
                  .filter((res) => {
                    if (res.fld_status == "Active") {
                      return res;
                    }
                  })
                  .map((data) => {
                    restaurantpath =
                      `/restaurants/restauranttype/${data.fld_name}`
                        .replace("%", "")
                        .replace("& ", "")
                        .replace(",", "")
                        .replace("$", "")
                        .replace("(", "")
                        .replace(")", "")
                        .replace(/ /g, "-") +
                      "/" +
                      data.fld_id;
                    return (
                      <div className="restaurant-type-wrap restaurant-type-wrap-home px-3">
                        <a
                          href={restaurantpath}
                          className="d-block text-decoration-none text-dark">
                          <div className="item min-height-auto cursor-pointer">
                            <img
                              src={data.fld_image}
                              className="services-icon"
                              alt=""
                              loading="lazy"
                            />
                            <h6 className="mb-0">{data.fld_name}</h6>
                          </div>
                        </a>
                      </div>
                    );
                  })
              ) : (
                <div className="row">
                  <div className="restaurant-type-wrap mb-4 text-center">
                    <Skeleton.Image active={true} />
                  </div>
                  <div className="restaurant-type-wrap mb-4 text-center">
                    <Skeleton.Image active={true} />
                  </div>
                  <div className="restaurant-type-wrap mb-4 text-center">
                    <Skeleton.Image active={true} />
                  </div>
                  <div className="restaurant-type-wrap mb-4 text-center">
                    <Skeleton.Image active={true} />
                  </div>
                  <div className="restaurant-type-wrap mb-4 text-center">
                    <Skeleton.Image active={true} />
                  </div>
                  <div className="restaurant-type-wrap mb-4 text-center">
                    <Skeleton.Image active={true} />
                  </div>
                  <div className="restaurant-type-wrap mb-4 text-center">
                    <Skeleton.Image active={true} />
                  </div>
                  <div className="restaurant-type-wrap mb-4 text-center">
                    <Skeleton.Image active={true} />
                  </div>
                  <div className="restaurant-type-wrap mb-4 text-center">
                    <Skeleton.Image active={true} />
                  </div>
                  <div className="restaurant-type-wrap mb-4 text-center">
                    <Skeleton.Image active={true} />
                  </div>
                  <div className="restaurant-type-wrap mb-4 text-center">
                    <Skeleton.Image active={true} />
                  </div>
                  <div className="restaurant-type-wrap mb-4 text-center">
                    <Skeleton.Image active={true} />
                  </div>
                  <div className="restaurant-type-wrap mb-4 text-center">
                    <Skeleton.Image active={true} />
                  </div>
                  <div className="restaurant-type-wrap mb-4 text-center">
                    <Skeleton.Image active={true} />
                  </div>
                </div>
              )}
            </div>
            {/* <div className=" d-lg-none d-block">
              <Carousel
                swipeable={true}
                draggable={true}
                showDots={false}
                responsive={responsive}
                arrows={false}
                ssr={true} // means to render carousel on server-side.
                infinite={true}
                keyBoardControl={true}
                autoPlaySpeed={1000}
                transitionDuration={500}
                containerClass="carousel-container"
                dotListClass="custom-dot-list-style"
                itemClass="me-3"
              >
                {resType.length > 0 ?
                  resType
                    .filter((res) => {
                      if (res.fld_status == "Active") {
                        return res;
                      }
                    })
                    .map((data) => {
                      // restaurantpath =
                      //   `/restaurants/restauranttype/${data.fld_name}`.replace(/ |_|%|---|&|-+/g, "-");
                      restaurantpath =
                        `/restaurants/restauranttype/${data.fld_name}`
                          .replace("%", "")
                          .replace("& ", "")
                          .replace(",", "")
                          .replace("$", "")
                          .replace("(", "")
                          .replace(")", "")
                          .replace(/ /g, "-") +
                        "/" +
                        data.fld_id;
                      return (
                        <div className="mx-2">
                          <a
                            href={restaurantpath}
                            className="d-block text-decoration-none text-dark"
                          >
                            <div className="item min-height-auto cursor-pointer">
                              <img
                                src={data.fld_image}
                                className="services-icon"
                                alt=""
                                loading="lazy"
                              />
                              <h6 className="mb-0">{data.fld_name}</h6>
                            </div>
                          </a>
                        </div>
                      );
                    }) :
                  <div className="d-flex">
                    <div className="col-12">
                      <Skeleton.Image active={true} className="skeleton-cuisine-image" />
                    </div>
                    <div className="col-12">
                      <Skeleton.Image active={true} className="skeleton-cuisine-image" />
                    </div>
                  </div>}
              </Carousel>
            </div> */}
          </div>
        </div>
      </section>
      <section className="restaurant_type">
        <div className="feat bg-gray pt-50 pb-50" data-scroll-index="2">
          <div className="container-fluid px-lg-4 hero">
            <div className="row">
              <div className="section-head col-lg-3">
                <h4>
                  <span>Explore</span>
                  <br />
                  <span className="grub-red">Cuisines</span>
                </h4>
              </div>
              <div className="section-head col-lg-9 d-flex justify-content-lg-end align-items-end">
                <h4 className="extra-text title-right">
                  Find a restaurant serving your <span>favorite</span> cuisine
                </h4>
              </div>
            </div>
            <div className="row cuisines restaurant-type-wrap-main d-flex">
              {cuisineType.length > 0 ? (
                cuisineType
                  .filter((res) => res.fld_name.toLowerCase().match(onSearch))
                  .map((data) => {
                    cuisinepath =
                      `/restaurants/cuisine/${data.fld_name}`
                        .replace("%", "")
                        .replace("& ", "")
                        .replace(",", "")
                        .replace("$", "")
                        .replace("(", "")
                        .replace(")", "")
                        .replace(/ /g, "-") +
                      "/" +
                      data.fld_id;
                    return (
                      <div className="cuisine-type-wrap px-3">
                        <a
                          href={cuisinepath}
                          className="d-block text-decoration-none text-dark">
                          <div className="item min-height-auto cursor-pointer">
                            <img
                              src={data.fld_image}
                              className="services-icon rounded"
                              alt=""
                              loading="lazy"
                            />
                            <h6 className="mb-0">{data.fld_name}</h6>
                          </div>
                        </a>
                      </div>
                    );
                  })
              ) : (
                <div className="row">
                  <div className="restaurant-type-wrap mb-4 text-center">
                    <Skeleton.Image active={true} />
                  </div>
                  <div className="restaurant-type-wrap mb-4 text-center">
                    <Skeleton.Image active={true} />
                  </div>
                  <div className="restaurant-type-wrap mb-4 text-center">
                    <Skeleton.Image active={true} />
                  </div>
                  <div className="restaurant-type-wrap mb-4 text-center">
                    <Skeleton.Image active={true} />
                  </div>
                  <div className="restaurant-type-wrap mb-4 text-center">
                    <Skeleton.Image active={true} />
                  </div>
                  <div className="restaurant-type-wrap mb-4 text-center">
                    <Skeleton.Image active={true} />
                  </div>
                  <div className="restaurant-type-wrap mb-4 text-center">
                    <Skeleton.Image active={true} />
                  </div>
                  <div className="restaurant-type-wrap mb-4 text-center">
                    <Skeleton.Image active={true} />
                  </div>
                  <div className="restaurant-type-wrap mb-4 text-center">
                    <Skeleton.Image active={true} />
                  </div>
                  <div className="restaurant-type-wrap mb-4 text-center">
                    <Skeleton.Image active={true} />
                  </div>
                  <div className="restaurant-type-wrap mb-4 text-center">
                    <Skeleton.Image active={true} />
                  </div>
                  <div className="restaurant-type-wrap mb-4 text-center">
                    <Skeleton.Image active={true} />
                  </div>
                  <div className="restaurant-type-wrap mb-4 text-center">
                    <Skeleton.Image active={true} />
                  </div>
                  <div className="restaurant-type-wrap mb-4 text-center">
                    <Skeleton.Image active={true} />
                  </div>
                </div>
              )}
            </div>
            {/* <div className="d-lg-none d-block cuisines">
              <Carousel
                swipeable={true}
                draggable={true}
                showDots={false}
                responsive={cuisinesresponsive}
                arrows={false}
                ssr={true} // means to render carousel on server-side.
                infinite={true}
                keyBoardControl={true}
                autoPlaySpeed={1000}
                transitionDuration={500}
                containerClass="carousel-container"
                dotListClass="custom-dot-list-style"
                itemClass="me-1"
              >
                {cuisineType.length > 0 ?
                  cuisineType
                    .filter((res) => res.fld_name.toLowerCase().match(onSearch))
                    .map((data) => {
                      cuisinepath =
                        `/restaurants/cuisine/${data.fld_name}`
                          .replace("%", "")
                          .replace("& ", "")
                          .replace(",", "")
                          .replace("$", "")
                          .replace("(", "")
                          .replace(")", "")
                          .replace(/ /g, "-") +
                        "/" +
                        data.fld_id;
                      return (
                        <div className="mx-2">
                          <a
                            href={cuisinepath}
                            className="d-block text-decoration-none text-dark"
                          >
                            <div className="item min-height-auto cursor-pointer">
                              <img
                                src={data.fld_image}
                                className="services-icon rounded"
                                alt=""
                                loading="lazy"
                              />
                              <h6 className="mb-0">{data.fld_name}</h6>
                            </div>
                          </a>
                        </div>
                      );
                    }) :
                  <div className="d-flex">
                    <div className="col-12">
                      <Skeleton.Image active={true} className="skeleton-cuisine-image" />
                    </div>
                    <div className="col-12">
                      <Skeleton.Image active={true} className="skeleton-cuisine-image" />
                    </div>
                  </div>
                }
              </Carousel>
            </div> */}
          </div>
        </div>
      </section>
      {/* <section className="services section-padding pb-70" data-scroll-index="3">
        <div className="container-fluid px-lg-4 hero">
          <div className="row">
            <div className="section-head col-lg-3">
              <h4>
                <span>Trending</span>
                <br />
                <span className="grub-red">Offers</span>
              </h4>
            </div>
            <div className="section-head col-lg-9 d-flex flex-column justify-content-lg-end align-items-end">
              <Link
                to="/restaurants"
                className="border border-danger p-2 rounded text-danger text-decoration-none d-block mb-2"
              >
                View all
              </Link>
              <h4 className="extra-text title-right">
                Explore exciting <span>food</span> deals
              </h4>
            </div>

            <div className="container-fluid px-lg-4">
              <Carousel
                swipeable={true}
                draggable={true}
                showDots={true}
                responsive={responsive}
                ssr={true} // means to render carousel on server-side.
                infinite={true}
                keyBoardControl={true}
                containerClass="carousel-container"
                dotListClass="custom-dot-list-style"
                itemClass="pb-4"
              >
                {offerList.map((val) => {
                  return (
                    <div className="mr-lg-2">
                      <img
                        src={val.fld_image}
                        className="img-fluid w-100 rounded"
                        alt=""
                      />
                    </div>
                  );
                })}
              </Carousel>
            </div>
          </div>
        </div>
      </section> */}
      <section
        className="bg-gray services section-padding featured-restaurant"
        data-scroll-index="3">
        <div className="container-fluid px-lg-4 hero">
          <div className="row">
            <div className="section-head d-flex align-items-end justify-content-between col-lg-3">
              <h4>
                <span>Featured</span>
                <br />
                <span className="grub-red">Restaurants</span>
              </h4>
              <a
                href="/restaurants"
                className="border border-danger p-2 rounded text-danger text-decoration-none d-block d-lg-none mb-2">
                View all
              </a>
            </div>
            <div className="section-head col-lg-9 d-flex flex-column justify-content-lg-end align-items-lg-end">
              <a
                href="/restaurants"
                className="border border-danger p-2 rounded text-danger text-decoration-none d-none d-lg-block mb-2">
                View all
              </a>
              <h4 className="extra-text title-right">
                Search for the <span>finest</span> dining experience
              </h4>
            </div>

            <div className="container-fluid px-lg-4 cuisines">
              <div className="row">
                {featuredRestaurant.length > 0 ? (
                  featuredRestaurant
                    .filter((res) =>
                      res.fld_restaurantname !== null
                        ? res.fld_restaurantname.toLowerCase().match(onSearch)
                        : featuredRestaurant
                    )
                    .slice(0, 8)
                    .map((data) => {
                      return <RestaurantCard featuredRestaurant={data} />;
                    })
                ) : (
                  <Row>
                    <Col lg={3} md={6} xs={12} className="mb-3">
                      <Skeleton.Image
                        active={true}
                        className="img-fluid w-100 rounded skeleteon-image-height mb-1"
                      />
                      <Skeleton active={true} block={false} className="mb-1" />
                    </Col>
                    <Col lg={3} md={6} xs={12} className="mb-3">
                      <Skeleton.Image
                        active={true}
                        className="img-fluid w-100 rounded skeleteon-image-height mb-1"
                      />
                      <Skeleton active={true} block={false} className="mb-1" />
                    </Col>
                    <Col lg={3} md={6} xs={12} className="mb-3">
                      <Skeleton.Image
                        active={true}
                        className="img-fluid w-100 rounded skeleteon-image-height mb-1"
                      />
                      <Skeleton active={true} block={false} className="mb-1" />
                    </Col>
                    <Col lg={3} md={6} xs={12} className="mb-3">
                      <Skeleton.Image
                        active={true}
                        className="img-fluid w-100 rounded skeleteon-image-height mb-1"
                      />
                      <Skeleton active={true} block={false} className="mb-1" />
                    </Col>
                  </Row>
                )}
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <section
        className="services section-padding featured-chefs"
        data-scroll-index="3"
      >
        <div className="container-fluid px-lg-4 hero">
          <div className="row">
            <div className="section-head col-lg-3">
              <h4>
                <span>Featured</span>
                <br />
                <span className="grub-red">Chefs</span>
              </h4>
            </div>
            <div className="section-head col-lg-9 d-flex flex-column justify-content-lg-end align-items-end">
              <Link
                to="/restaurants"
                className="border border-danger p-2 rounded text-danger text-decoration-none d-block mb-2"
              >
                View all
              </Link>
              <h4 className="extra-text title-right">
                Tryout the best <span>culinary delicacies</span> of chefs
              </h4>
            </div>
            {featuredRestaurant.length > 0 ? (
              <Carousel
                swipeable={true}
                draggable={true}
                showDots={false}
                responsive={responsive}
                ssr={true} // means to render carousel on server-side.
                infinite={true}
                keyBoardControl={true}
                containerClass="carousel-container"
                dotListClass="custom-dot-list-style"
                itemClass="mx-2"
              >
                {featuredRestaurant.length > 0
                  ? featuredRestaurant
                      ?.filter((param) => param.fld_is_chef?.includes("Yes"))
                      .map((data) => (
                        <div className="">
                          <Link
                            to="/restaurant-info"
                            className="text-dark text-decoration-none"
                          >
                            <div className="item min-height-auto p-0">
                              <div className="position-relative">
                                <img
                                  src={data.fld_image}
                                  className="img-fluid w-100"
                                  alt=""
                                />
                                {/* <span className="featured-restaurant-rating">
                            {data.rating} <IoStar />
                          </span>
                                <div className="d-lg-flex justify-content-between align-items-center like-and-rating">
                                  <a
                                    href=""
                                    className="text-decoration-none text-dark"
                                  >
                                    <div className="like-block">
                                      <span className="mr-2">
                                        {data.fld_rating}
                                      </span>
                                      <BiLike className="rating-star" />
                                    </div>
                                  </a>
                                  <a
                                    href=""
                                    className="text-decoration-none text-dark"
                                  >
                                    <div className="rating-block">
                                      <span className="mr-2">
                                        {" "}
                                        <Rating
                                          placeholderRating={data.fld_rating}
                                          emptySymbol={
                                            <img
                                              src={stargray}
                                              className="icon"
                                            />
                                          }
                                          placeholderSymbol={
                                            <img
                                              src={starred}
                                              className="icon"
                                            />
                                          }
                                          fullSymbol={
                                            <img
                                              src={staryellow}
                                              className="icon"
                                            />
                                          }
                                        />
                                      </span>
                                    </div>
                                  </a>
                                </div>
                              </div>
                              <div className="p-2">
                                <h5 className="mb-1 fw-bold">
                                  {data.fld_restaurantname}
                                </h5>
                                <p className="mb-2 cuisine-info">
                                  {data.fld_cuisines}
                                </p>
                                <hr className="my-2" />
                                <p className="mb-0">{data.fld_address}</p>
                              </div>
                            </div>
                          </Link>
                        </div>
                      ))
                  : ""}
              </Carousel>
            ) : (
              ""
            )}
          </div>
        </div>
      </section> */}
      {/* <section
        className="bg-gray services section-padding pb-70 featured-events"
        data-scroll-index="3"
      >
        <div className="container-fluid px-lg-4 hero">
          <div className="row">
            <div className="section-head col-lg-3">
              <h4>
                <span>Happening</span>
                <br />
                <span className="grub-red">Events</span>
              </h4>
            </div>
            <div className="section-head col-lg-9 d-flex flex-column justify-content-lg-end align-items-end">
              <Link
                to="/restaurants"
                className="border border-danger p-2 rounded text-danger text-decoration-none d-block mb-2"
              >
                View all
              </Link>
              <h4 className="extra-text title-right">
                Checkout events <span>happening</span> around you
              </h4>
            </div>

            <Carousel
              swipeable={true}
              draggable={true}
              showDots={false}
              responsive={responsive}
              ssr={true} // means to render carousel on server-side.
              infinite={true}
              keyBoardControl={true}
              containerClass="carousel-container"
              dotListClass="custom-dot-list-style"
              itemClass="mx-2"
            >
              {eventList.map((val) => {
                return (
                  <div>
                    <Link to="/">
                      <img
                        src={val.fld_eventimage}
                        className="img-fluid w-100 rounded"
                        alt=""
                      />
                    </Link>
                  </div>
                );
              })}
            </Carousel>
          </div>
        </div>
      </section> */}
      {/* <section>
        <div className="container-fluid px-4 pb-5">
          <div className="row">
            <div className="section-head col-sm-12">
              <h4>
                <span>Trending</span>
                <br />
                <span className="grub-red">Offers</span>
              </h4>
            </div>
          </div>
          <storyly-web ref={storylyRef} />
        </div>
      </section> */}
      <Offcanvas
        show={showNavbar}
        onHide={handleNavbar}
        placement="bottom"
        className="search-offcanvas">
        <Offcanvas.Header
          closeButton
          className=" border-0 justify-content-end"></Offcanvas.Header>
        <Offcanvas.Body className="pt-0 overflow-x-hidden">
          <div className="search-input sticky-top bg-white pb-2">
            <input
              value={searchText}
              onChange={(e) => {
                setSearchText(e.target.value);
              }}
              placeholder="Look for restaurants, location, cuisines or a dish"
            />
            <span className="search-icon">
              <BiSearchAlt className="search-icon-svg" />
            </span>
          </div>
          {searchData
            .filter((dat) => {
              if (
                dat.fld_name.toLowerCase().includes(searchText.toLowerCase())
              ) {
                return dat;
              }
              if (
                dat.fld_address.toLowerCase().includes(searchText.toLowerCase())
              ) {
                return dat;
              }
            })
            .map((data) => {
              return (
                <div
                  onClick={() => {
                    navigate(`${data.fld_route}`, {
                      state: data.fld_client_id,
                    });
                  }}
                  className="d-flex search-result-card">
                  <img
                    src={data.fld_image}
                    alt="img"
                    className="dish-image"
                    loading="lazy"
                  />
                  <div>
                    <div
                      className="review-profile-info fw-bold mb-1"
                      style={{ marginLeft: "8px" }}>
                      {data.fld_name}
                    </div>
                    <div
                      style={{
                        marginLeft: "8px",
                        display: data.fld_address == null ? "none" : "block",
                      }}>
                      {data.fld_address}
                    </div>
                  </div>
                </div>
              );
            })}
        </Offcanvas.Body>
      </Offcanvas>
    </>
  );
}

export default Home;
