import React from 'react';
import { BiLike } from 'react-icons/bi';
import Rating from "react-rating";
import { Link } from 'react-router-dom';
import stargray from "../../assets/images/rating/star-grey.png";
import starred from "../../assets/images/rating/star-red.png";
import staryellow from "../../assets/images/rating/star-yellow.png";

function ChefCard(props) {
  return (
    <>
      {props.featuredRestaurant
        ?.filter((param) => param.fld_is_chef?.includes("Yes"))
        .map((data) => (
          <div className="col-lg-3">
            <a
              href="/restaurant-info"
              className="text-dark text-decoration-none"
            >
              <div className="item min-height-auto p-0">
                <div className="position-relative">
                  <img src={data.image} className="img-fluid w-100" alt="" />
                  {/* <span className="featured-restaurant-rating">
                            {data.rating} <IoStar />
                          </span> */}
                  <div className="d-lg-flex justify-content-between align-items-center like-and-rating">
                    <a href="" className="text-decoration-none text-dark">
                      <div className="like-block">
                        <span className="mr-2">{data.like}</span>
                        <BiLike className="rating-star" />
                      </div>
                    </a>
                    <a href="" className="text-decoration-none text-dark">
                      <div className="rating-block">
                        <Rating
                          placeholderRating={data.fld_rating}
                          emptySymbol={<img src={stargray} className="icon" />}
                          placeholderSymbol={
                            <img src={starred} className="icon" />
                          }
                          fullSymbol={<img src={staryellow} className="icon" />}
                        />
                      </div>
                    </a>
                  </div>
                </div>
                <div className="p-2">
                  <h5 className="mb-1 fw-bold">{data.fld_restaurantname}</h5>
                  <p className="mb-2 cuisine-info">{data.cusines_list}</p>
                  <hr className="my-2" />
                  <p className="mb-0">{data.fld_address}</p>
                </div>
              </div>
            </a>
          </div>
        ))}
    </>
  );
}

export default ChefCard;