import React, { useEffect } from "react";
import HeadBanner from "../../assets/images/grub-web-banner.png";
import AboutImage from "../../assets/images/about-img.png";
import FoodWebsite from "../../assets/images/services/menu.png";
import FoodApp from "../../assets/images/services/order-food.png";
import KOT from "../../assets/images/services/order.png";
import SalesManagement from "../../assets/images/services/laptop.png";
import EndSolution from "../../assets/images/features/end-solution.png";
import customizationSolution from "../../assets/images/features/customization-solution.png";
import DeviceCompatibility from "../../assets/images/features/device-compatibility.png";
import StableSystem from "../../assets/images/features/stable-system.png";
import UserExperience from "../../assets/images/features/user-experience.png";
import PrivacyIcon from "../../assets/images/features/privacy.png";
import FoodPhotography from "../../assets/images/services/food_photography.png";
import EventPhotosVideos from "../../assets/images/services/event_videos_photography.png";
import RestaurantPhotography from "../../assets/images/services/restaurant__photography.png";
import ReelsVideography from "../../assets/images/services/videography.png";
import SEO from "../../assets/images/marketing/seo.png";
import SocialMedia from "../../assets/images/marketing/social-media.png";
import DigitalAdvertising from "../../assets/images/marketing/digital-advertising.png";
import Penguin from "../../assets/images/penguin.png";

function Home() {
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 0);
  }, []);
  return (
    <>
      <header className="hero-container">
        <div className="container-fluid px-4">
          <div className="row">
            <div className="col-lg-8 pr-lg-5 pr-0 d-flex flex-column align-items-start justify-content-center order-lg-1 order-2">
              <h2 className="extra-text">
                Take your <span>Restaurant</span> &{" "}
                <span>Food Delivery Business</span> Online
              </h2>
              <p className="mb-lg-2 mb-2">
                Use the Grub Digest Online Food Ordering Platform to feed your
                customers using your own website.
              </p>
              <h6 className="mb-lg-2 mb-2 font-special">
                Get Unlimited Orders with{" "}
                <span className="grub-red text-large">0</span>
                <span className="grub-red">%</span> Commission...!
              </h6>
            </div>
            <div className="col-lg-4 order-lg-2 order-1 py-lg-0 py-4">
              <img src={HeadBanner} className="" alt="" />
            </div>
          </div>
        </div>
      </header>
      <section className="hero section-padding pb-0" data-scroll-index="1">
        <div className="container-fluid px-4">
          <div className="row" style={{ padding: "0 0 50px 0" }}>
            <div className="col-lg-12 text-center mb-80">
              <h4 className="extra-text">
                We provide an <span>Online Food Ordering</span> Platform for
                your Restaurant
              </h4>
              <p className="px-lg-5 px-0 mx-auto about-des">
                We provide your customers with an easy-to-use ordering experience directly from your website and offer a robust backend platform to manage both the Online Food Ordering System and your customers.
              </p>
            </div>

            <div className="col-lg-8">
              <div className="mission">
                <div className="section-head mb-30">
                  <h4>
                    <span>About</span>
                    <br />
                    <span className="grub-red">Grub</span>Digest
                  </h4>
                </div>
                <h6 className="mb-3">
                  Convert your website visitors into prospective customers to
                  order food from your restaurant online.
                </h6>
                <p className="mb-3">
                  GrubDigest is a robust Online Food Ordering Platform that
                  provides a technology bridge for enabling your restaurant or
                  hotel to deliver food online from your own customised website.
                  Our solution helps restaurant owners to increase profits as
                  they don’t need to pay any commissions as they do with Other
                  Food Ordering Aggregators. The solution we provide comes with
                  a full support system enabling you to get the best sales
                  results. Our Backend Platform enables you to run the ordering
                  system seamlessly.
                </p>
                <p className="mb-3">
                  We also provide you an end to end digital marketing solution
                  tailor made to promote your restaurant to customers in your
                  location to get more sales orders.
                </p>
                <h6 className="mb-2">
                  Get ready to start selling your Food Online. Talk to our
                  experts <span className="grub-red">NOW!</span>
                </h6>
              </div>
            </div>

            <div className="col-lg-4">
              <img src={AboutImage} className="img-responsive" />
            </div>
          </div>
        </div>
      </section>
      <div className="numbers section-padding bg-img">
        <div className="container-fluid px-4">
          <div className="row">
            <div className="col-md-12">
              <h2 style={{ textAlign: "center", color: "#fff" }}>
                A one stop Software &amp; Mobility solution <br /> for Online
                Food Ordering
              </h2>
            </div>
          </div>
        </div>
      </div>
      <section>
        <div className="feat bg-gray pt-50 pb-50 hero" data-scroll-index="2">
          <div className="container-fluid px-4">
            <div className="row">
              <div className="section-head col-sm-12">
                <h4>
                  <span>Our</span>
                  <br />
                  Solutions
                </h4>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="item">
                  <img src={FoodWebsite} className="services-icon" alt="" />
                  <h6>Online Food Ordering Website</h6>
                  <p>
                    Our solution includes a fully integrated and customised
                    online food ordering website for customers to place orders
                    directly.
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="item">
                  <img src={FoodApp} className="services-icon" alt="" />
                  <h6>Food Ordering App</h6>
                  <p>
                    With mobile based users being the larger part who would
                    order online we give customers customised Progressive Web &
                    Mobile Apps.
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="item">
                  <img src={KOT} className="services-icon" alt="" />
                  <h6>Kitchen Order Tickets (KOT)</h6>
                  <p>
                    Managing your online delivery and takeaway orders are so
                    simple using our Kitchen Order Tickets (KOT) Application.
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="item">
                  <img src={SalesManagement} className="services-icon" alt="" />
                  <h6>Sales & Order Management Panel</h6>
                  <p>
                    Manage your Menu, Customers, Offers & View Sales Reports
                    using our comprehensive Management Application.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <div className="numbers section-padding bg-img">
        <div className="container-fluid px-4">
          <div className="row">
            <div className="col-md-12">
              <h2 style={{ textAlign: "center", color: "#fff" }}>
                Schedule a call with us to go through <br /> our Check-in
                Process
              </h2>
            </div>
            <div className="col-md-12 text-center">
              <a href={`tel:7827795895`} className="connect" >
                <span>Click to Schedule a Call</span>
              </a>
            </div>
          </div>
        </div>
      </div>
      <section className="services section-padding pb-70" data-scroll-index="3">
        <div className="container-fluid px-4">
          <div className="row">
            <div className="section-head col-sm-12">
              <h4>
                <span className="grub-red">Grub</span>
                <span>Digest</span>
                <br />
                Top Features
              </h4>
            </div>

            <div className="col-lg-4 col-md-6">
              <div className="item">
                <img src={EndSolution} className="services-icon" alt="" />
                <h6>End to End Solution</h6>
                <p>
                  We provide a full fledged technical and marketing solutions to
                  get your food ordering system online.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="item">
                <img src={UserExperience} className="services-icon" alt="" />
                <h6>Amazing User Experience</h6>
                <p>
                  Our online ordering platform gives both the customer and the
                  restaurant user a very easy to use feel.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="item">
                <img
                  src={DeviceCompatibility}
                  className="services-icon"
                  alt=""
                />
                <h6>Device Compatibility</h6>
                <p>
                  Our applications are tailor-made for Mobile, Desktop &
                  Tablets. This makes usability very easy.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="item">
                <img
                  src={customizationSolution}
                  className="services-icon"
                  alt=""
                />
                <h6>Customised Solution</h6>
                <p>
                  We creatively design a unique full customised online ordering
                  website for each and every restaurant.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="item">
                <img src={StableSystem} className="services-icon" alt="" />
                <h6>Stable System</h6>
                <p>
                  Our platform goes through various rigorous tests enabling you
                  to have a seamless way to operate.
                </p>
              </div>
            </div>
            <div className="col-lg-4 col-md-6">
              <div className="item">
                <img src={PrivacyIcon} className="services-icon" alt="" />
                <h6>Privacy</h6>
                <p>
                  We ensure the each customers data is encrypted and securely
                  stored on our cloud based servers.
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="serv-tabs section-padding bg-img text-white position-relative overflow-x-clip pb-md-5 pb-lg-0">
        <div className="container-fluid px-4 pb-md-5">
          <div className="row">
            <div className="section-head col-sm-12">
              <h4>
                <span>Digital Marketing</span>
                <br />
                Solutions
              </h4>
            </div>
            <div className="row">
              <div className="col-lg-8 text-center mx-auto">
                <p className="mt-0 mb-4 mx-auto text-white px-3 text-justify">
                  When you need to get more Visibility, Reach and better Search
                  Engine Ranking, Digital Marketing is a key tool that ensures
                  your Online Food Delivery Business ranks higher on Search
                  Engines. This includes online promotion to not only sell
                  online but for customers to recall the name of our Restaurant
                  more often.
                </p>
              </div>
            </div>
          </div>
          <div className="row justify-content-center">
            <div className="tabs col-sm-12">
              <div className="tabs-icon row justify-content-center">
                <div
                  id="tab-1"
                  className=" col-lg-3 col-sm-12 col-xs-12 item act"
                >
                  <div>
                    <img src={SEO} className="how" alt="" />

                    <h6>Search Engine Optimization (SEO)</h6>

                    <p className="how-it-works">
                      To get more customers & orders you need to have your
                      website searchable on search engines. This requires a
                      regular activity of Search Engine Optimisation (SEO) to be
                      done for your website. Our websites are all SEO friendly
                      and can get you quick results. Our team of SEO Experts
                      work regularly on your website to ensure you get the
                      maximum reach.
                    </p>
                  </div>
                </div>

                <div
                  id="tab-2"
                  className="col-lg-3 col-sm-12 col-xs-12 item act"
                >
                  <div>
                    <img src={SocialMedia} className="how" alt="" />

                    <h6>Social Media</h6>
                    <p className="how-it-works">
                      The use of social media platforms today to promote your
                      restaurant is the best possible way to reach more and more
                      customers and generate good sales and increase revenue.
                      Our team of dedicated social media experts can give you
                      creative solutions in having your restaurant showcased on
                      platforms like Facebook, Instagram, LinkedIn & Youtube
                      etc.,
                    </p>
                  </div>
                </div>

                <div
                  id="tab-3"
                  className="col-lg-3 col-sm-12 col-xs-12 item act"
                >
                  <div>
                    <img src={DigitalAdvertising} className="how" alt="" />
                    <h6>Digital Advertising</h6>
                    <p className="how-it-works">
                      To get instant results and reaching more customers in a
                      short span of time the use of Digital Advertising can
                      really be very helpful. We have a team who can support you
                      in promoting your website using Google Ads, Facebook &
                      Instagram Ads. Special Offers can easily be promoted for
                      specific occasions using the power of Digital Advertising
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <img src={Penguin} className="Penguin" alt="" />
      </section>
      <section>
        <div className="feat bg-gray pt-50 pb-50 hero" data-scroll-index="2">
          <div className="container-fluid px-4">
            <div className="row">
              <div className="section-head col-sm-12">
                <h4>
                  <span>Photography</span>
                  <br />
                  Solutions
                </h4>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="item">
                  <img src={FoodPhotography} className="services-icon" alt="" />
                  <h6>Food Photography</h6>
                  <p>
                    Food Photography is an Art that gives life to Food.
                    Attractive food and drinks images can be very helpful in
                    marketing your Restaurant.
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="item">
                  <img
                    src={RestaurantPhotography}
                    className="services-icon"
                    alt=""
                  />
                  <h6>Restaurant Photography</h6>
                  <p>
                    Restaurant Photography that captures the ambience, food and
                    drinks can enable you to showcase the dining experience you
                    provide.
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="item">
                  <img
                    src={ReelsVideography}
                    className="services-icon"
                    alt=""
                  />
                  <h6>Videography for Reels</h6>
                  <p>
                    Video Reels of your Restaurant, Food & Beverages can help
                    you to reach out to your customers on social media and other
                    digital platforms
                  </p>
                </div>
              </div>
              <div className="col-lg-3 col-md-6">
                <div className="item">
                  <img
                    src={EventPhotosVideos}
                    className="services-icon"
                    alt=""
                  />
                  <h6>Event Video & Photography</h6>
                  <p>
                    Events draw customers & a collection of memories of the
                    events that had happened can be helpful in getting new
                    customers
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}

export default Home;