import { Skeleton } from 'antd';
import createDOMPurify from "dompurify";
import Notiflix from 'notiflix';
import React, { useEffect, useState } from 'react';
import { Col, Container, Row } from 'react-bootstrap';
import GetApiCall from '../../API/GETAPI';
import PostApiCall from '../../API/POSTAPI';
import ContactUsImage from "../../assets/images/contact-us.png";
import ContactUsImageFree from "../../assets/images/contact-us/grub-digest-contact-1.png";
import ContactUsImageOnline from "../../assets/images/contact-us/grub-digest-contact-2.png";
import ContactUsImageDigital from "../../assets/images/contact-us/grub-digest-contact-3.png";
import ContactUsImagePhoto from "../../assets/images/contact-us/grub-digest-contact-4.png";

function ContactUs() {
  const [name, setName] = useState("");
  const [bussinessName, setBussinessName] = useState("");
  const [mobile, setMobile] = useState("");
  const [email, setEmail] = useState("");
  const [location, setLocation] = useState("");
  const [message, setMessage] = useState("");
  const [officeAddress, setOfficeAddress] = useState([]);
  const [captcha_number, setcaptcha_number] = useState("");
  const [loading, setLoading] = useState(true);
  const [Rotatecaptcha, setRotatecaptcha] = useState(false);
  const [Enquiry, setEnquiry] = useState({
    captcha: "",
  })
  const DOMPurify = createDOMPurify(window);
  useEffect(() => {
    // scroll to top on page load
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
    setTimeout(() => {
      setLoading(false)
    }, 2000);
  }, []);
  const [scroll, setScroll] = useState(false);
  useEffect(() => {
    window.addEventListener("scroll", () => {
      setScroll(window.scrollY > 500);
    });
  }, []);

  // Genrate captcha
  function Genratecaptcha() {
    var reloadcapcha = Math.floor(100000 + Math.random() * 90000000).toString(36)
    [Math.random() < 0.5 ? "toString" : "toUpperCase"]();
    setcaptcha_number(reloadcapcha);
    setRotatecaptcha(!Rotatecaptcha);
  }

  useEffect(() => {
    if (Rotatecaptcha == "") {
      setRotatecaptcha("captcha-rotate");
    }
  }, [Rotatecaptcha]);

  useEffect(() => {
    setcaptcha_number(Math.floor(100000 + Math.random() * 90000000).toString(36)
    [Math.random() < 0.5 ? "toString" : "toUpperCase"]());
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();

    if (name != "") {
      if (mobile != "") {
        if (mobile.length == 10) {
          if (email != "") {
            if (
              /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                email
              )
            ) {
              if (location != "") {
                onPostForm();
              }
              else {
                Notiflix.Notify.failure("Enter Location.");
              }
            } else {
              Notiflix.Notify.failure("Enter Valid Email.");
            }
          } else {
            Notiflix.Notify.failure("Enter Email.");
          }
        } else {
          Notiflix.Notify.failure("Enter Valid Contact Number.");
        }
      } else {
        Notiflix.Notify.failure("Enter Contact Number.");
      }
    } else {
      Notiflix.Notify.failure("Enter Contact Person.");
    }
  };

  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 0);
    GetApiCall.getRequest("getOfficeAddress").then((results) =>
      results?.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          if (obj1.message.length != 0) {
            setOfficeAddress(obj1.message);
          }
        }
      })
    );
  }, []);
  const onPostForm = () => {
    Notiflix.Loading.circle();
    PostApiCall.postRequest(
      {
        id: null,
        contactperson: name,
        contactnumber: mobile,
        restaurant: bussinessName == "" ? null : bussinessName,
        email: email,
        location: location,
        message: message == "" ? null : message,
      },
      "updateenquiries"
    ).then((result) =>
      result?.json().then((obj) => {
        if (result.status == 200 || result.status == 201) {
          // PostApiCall.postRequest(
          //   {
          //     id: null,
          //     contactperson: name,
          //     contactnumber: mobile,
          //     restaurant: bussinessName == "" ? null : bussinessName,
          //     email: email,
          //     location: location,
          //     message: message == "" ? null : message,
          //   },
          //   "mailer"
          // ).then((results) =>
          //   // const objs = JSON.parse(result._bodyText)
          //   results?.json().then((obj) => {
          //     if (results.status == 200 || results.status == 201) {
          //       Notiflix.Loading.remove();
          //       Notiflix.Notify.success(
          //         "Your form submitted successfully. Our team will get back to you shortly."
          //       );
          //       setName("");
          //       setEmail("");
          //       setBussinessName("");
          //       setMobile("");
          //       setMessage("");
          //       setLocation("");
          //     }
          //   })
          // );
          Notiflix.Loading.remove();
          Notiflix.Notify.success("Your form submitted successfully. Our team will get back to you shortly.");
          setName("");
          setEmail("");
          setBussinessName("");
          setMobile("");
          setMessage("");
          setLocation("");
        }
      })
    );
  };


  return (
    <section className="contact">
      <div className="container-fluid">
        {loading == true ?
          <Row>
            <Col lg={12} xs={12} className="my-5">
              <Skeleton.Input active={true} className='w-100' />
            </Col>
            <Col lg={3} xs={6} className="mb-3">
              <Skeleton.Image active={true} className="img-fluid w-100 rounded skeleteon-image-height" />
            </Col>
            <Col lg={3} xs={6} className="mb-3">
              <Skeleton.Image active={true} className="img-fluid w-100 rounded skeleteon-image-height" />
            </Col>
            <Col lg={3} xs={6} className="mb-3">
              <Skeleton.Image active={true} className="img-fluid w-100 rounded skeleteon-image-height" />
            </Col>
            <Col lg={3} xs={6} className="mb-3">
              <Skeleton.Image active={true} className="img-fluid w-100 rounded skeleteon-image-height" />
            </Col>
            <Col lg={6} xs={12} className="mb-3">
              <Skeleton.Image active={true} className="img-fluid w-100 rounded skeleteon-image-height" />
            </Col>
            <Col lg={6} xs={12} className="mb-3">
              <Skeleton.Image active={true} className="img-fluid w-100 rounded skeleteon-image-height" />
            </Col>
          </Row>
          :
          <>
            <Row>
              <div className="col-12 text-center mb-4">
                <h3 className="mb-2 fw-bold">
                  Get an Accelerated Traction for your Food Business
                </h3>
                <h6 className="mb-2">
                  We have you covered in all aspects to get your Food Business to
                  the next level. Get in touch with us to drive your business ahead.
                </h6>
              </div>
            </Row>
            <Row className="my-3">
              <Col lg={3} md={6} className="mb-4">
                <img
                  src={ContactUsImageFree}
                  className="img-fluid rounded"
                  alt=""
                />
              </Col>
              <Col lg={3} md={6} className="mb-4">
                <img
                  src={ContactUsImageOnline}
                  className="img-fluid rounded"
                  alt=""
                />
              </Col>
              <Col lg={3} md={6} className="mb-4">
                <img
                  src={ContactUsImageDigital}
                  className="img-fluid rounded"
                  alt=""
                />
              </Col>
              <Col lg={3} md={6} className="mb-4">
                <img
                  src={ContactUsImagePhoto}
                  className="img-fluid rounded"
                  alt=""
                />
              </Col>
            </Row>
            <div className="row justify-content-center">
              <div className="col-lg-6 contact-form">
                <div class="section-head col-sm-12 px-lg-0">
                  <h4>
                    <span>Get</span>
                    <br />
                    In Touch
                  </h4>
                </div>
                <div className="card p-3 shadow-lg mx-lg-2 mx-3">
                  <form onSubmit={handleSubmit} method="post" id="form1">
                    <div class="controls">
                      <div class="row">
                        <div className="col-md-12">
                          <p className="mb-lg-3 mb-2">
                            Tell us a little about you so that our experts can get
                            in touch with you
                          </p>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group">
                            <input
                              name="txtname"
                              type="text"
                              id="txtname"
                              placeholder="Contact Person *"
                              value={name}
                              onChange={(e) => { setName(e.target.value) }}
                            />
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group">
                            <input
                              name="txtsub"
                              type="text"
                              id="txtsub"
                              placeholder="Business Name"
                              value={bussinessName}
                              onChange={(e) => { setBussinessName(e.target.value) }}
                            />
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group">
                            <input
                              name="txtnumber"
                              type="text"
                              id="txtnumber"
                              placeholder="Contact Number *"
                              value={mobile}
                              // onChange={(e) => { setMobile(e.target.value) }}
                              onChange={(e) => {
                                if (e.target.value.length <= 10) {
                                  setMobile(e.target.value);
                                }
                              }}

                            />
                          </div>
                        </div>
                        <div class="col-md-12">
                          <div class="form-group">
                            <input
                              name="txtemail"
                              type="text"
                              id="txtemail"
                              placeholder="Email *"
                              value={email}
                              onChange={(e) => { setEmail(e.target.value) }}
                            />
                          </div>
                        </div>

                        <div class="col-md-12">
                          <div class="form-group">
                            <input
                              name="txtcountry"
                              type="text"
                              id="txtcountry"
                              placeholder="Location *"
                              value={location}
                              onChange={(e) => { setLocation(e.target.value) }}
                            />
                          </div>
                        </div>

                        <div class="col-md-12">
                          <div class="form-group">
                            <textarea
                              name="txtmsg"
                              rows="4"
                              cols="20"
                              id="txtmsg"
                              placeholder="Message"
                              value={message}
                              onChange={(e) => { setMessage(e.target.value) }}
                            ></textarea>
                          </div>
                        </div>

                        <div class="col-md-12 text-center">
                          <input
                            type="submit"
                            name="btnsubmit"
                            value="Send Message"
                            id="btnsubmit"
                            class="sub-button"
                          // onClick={onPostForm}
                          />
                        </div>
                      </div>
                    </div>
                  </form>
                </div>
              </div>
              <div className="col-lg-6 text-center contact-block">
                {/* <div id="ieatmaps"></div> */}
                <div className="row flex-column">
                  <div className="col-lg-12">
                    <img src={ContactUsImage} className="contact-image" alt="" />
                  </div>
                </div>
              </div>
            </div>
            <div className="bg-light py-2">
              <Container fluid className="">
                <Row className="py-lg-2 py-5">
                  <div className="col-lg-6">
                    <h3 className="fw-bold">
                      Grub Digest{" "}
                      <span className="text-capitalize mb-0 text-dark fs-6 fw-normal fst-italic">
                        a product of{"  "}
                        <a
                          href="http://globaltrendz.com/"
                          className="text-dark text-decoration-none mx-1"
                          target="_blank">
                          Global Trendz
                        </a>
                      </span>
                    </h3>
                    {officeAddress.map((data) => {
                      return (
                        <div className="text-dark mb-1 d-flex mb-2 lets-contact-address align-items-center" >
                          <div className="fw-bold mx-1">{data.fld_branch}{" "} - {" "}</div><div
                            dangerouslySetInnerHTML={{
                              __html: DOMPurify.sanitize(
                                data.fld_address
                              ),
                            }}
                          ></div>
                        </div>
                      )
                    })}
                    <div className="cont">
                      <div className="d-flex justify-content-start">
                        <a
                          href="mailto:wecare@grubdigest.com"
                          className="d-block text-decoration-none"
                        >
                          <p className="text-dark mb-0">
                            wecare@grubdigest.com&nbsp;|&nbsp;
                          </p>
                        </a>

                        <a
                          href="https://www.grubdigest.com"
                          className="d-block text-decoration-none"
                        >
                          <p className="text-dark mb-0">www.grubdigest.com</p>
                        </a>
                      </div>
                    </div>
                  </div>
                  <div className="col-lg-5 d-flex justify-content-lg-end justify-content-start align-items-center mt-lg-2 mt-5">
                    <div className="grub-red text-center">
                      <h1 className="fw-bold">Let's talk.</h1>
                      {/* <p className="fw-400 text-dark">Share your excitement with us.</p> */}
                      <a href={`tel:7827795895`} className="btn btn-danger btn-lg">
                        +91 7827 795 895
                      </a>
                    </div>
                  </div>
                </Row>
              </Container>
            </div>
          </>}
      </div>
    </section>
  );
}

export default ContactUs