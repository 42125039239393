//
// var urlString = "http://192.168.1.23:3012/api-grubwebsite/";
// var urlString = "http://192.168.1.47:3012/api-grubwebsite/";
var urlString = "https://www.grubdigest.com/api-grubwebsite/";
// var urlString = "http://localhost:3012/api-grubwebsite/";

const GetApiCall = {
  getRequest(url) {
    return fetch(urlString + url, {
      method: "GET",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        schema: "grubdigest_usr",
      },
    })
      .then((response) => {
        // console.log(response)
        return response;
      })
      .catch((error) => {
        console.log("request failed", error);
        return error;
      });
  },
};

export default GetApiCall;

// var urlString = "http://grubdigest.com/api-grub/";
// const GetApiCall = {
//   getRequest(url) {

//     return fetch(urlString + url, {
//       method: "GET",
//       headers: {
//         Accept: "application/json",
//         "Access-Control-Allow-Origin": "*",
//         "Access-Control-Allow-Headers": "*",
//         "Content-Type": "application/json",

//       },
//     });
//   },
// };

// export default GetApiCall;
