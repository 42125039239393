import Notiflix from "notiflix";
import React, { useEffect, useState } from "react";
import { Button, FloatingLabel, Form, Offcanvas } from "react-bootstrap";
import GetApiCall from "../../API/GETAPI";
import PostApiCall from "../../API/POSTAPI";
import logo from "../../logo.png";
import ThankyouOffcanvas from "../ThankyouOffcanvas/ThankyouOffcanvas";
import SignUpImage from "./SignUpImage";
import { useContext } from "react";
import { store } from "../../Context/store";

function Navbar() {
  const [scroll, setScroll] = useState(false);
  const [show, setShow] = useState(false);
  const [showFormModal, setShowFormModal] = useState(false);
  const [showNavbar, setShowNavbar] = useState(false);
  // const handleFormShow = () => setShowFormModal(!showFormModal);
  const [contactPerson, setContactPerson] = useState("");
  const [restaurantName, setRestaurantName] = useState("");
  const [address, setAddress] = useState("");
  const [mobileNumber, setMobileNumber] = useState("");
  const [email, setEmail] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [country, setCountry] = useState("");
  const [countries, setCountries] = useState([]);
  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [showThankyouModal, setShowThankyouModal] = useState(false);
  const [checkEmail, setCheckEmail] = useState("");
  const { homeVisited, setHomeVisited } = useContext(store)
  useEffect(() => {
    if (sessionStorage.getItem("HomepageVisited") === "true") {
      setHomeVisited(false)
    } else {
      if (homeVisited) {
        handleFormShow();
      }
    }
  }, [homeVisited])
  useEffect(() => {
    if (window.pageYOffset > 0) {
      setScroll("scroll");
    } else {
      window.addEventListener("scroll", () => {
        setScroll(window.scrollY > 0);
      });
    }
    if (window.pageYOffset > 0) {
      setScroll("scrollSticky");
    } else {
      window.addEventListener("scrollSticky", () => {
        setScroll(window.scrollY > 0);
      });
    }
    GetApiCall.getRequest("getCountry").then((results) =>
      results?.json().then((obj) => {
        if (results.status == 200 || results.status == 201) {
          setCountries(obj.message);
        }
      })
    );
    // setModalShow(true)
  }, []);
  const handleFormShow = () => {
    setShowFormModal(true)
    // window.location.href = "/list-your-food-business"

  }
  const handleFormHide = () => {
    sessionStorage.setItem("HomepageVisited", "true")
    setHomeVisited(false)
    setShowFormModal(false)
  };
  const handleNavbar = () => setShowNavbar(false);

  function getStates(country) {
    PostApiCall.postRequest({ countryid: country }, "getState").then(
      (resultcategory) =>
        resultcategory.json().then((obj) => {
          if (resultcategory.status == 200 || resultcategory.status == 201) {
            setStates(obj.message);
          }
        })
    );
  }




  function getCities(state) {
    PostApiCall.postRequest({ stateid: state }, "getCity").then(
      (resultcategory) =>
        resultcategory.json().then((obj) => {
          if (resultcategory.status == 200 || resultcategory.status == 201) {
            setCities(obj.message);
          }
        })
    );
  }
  return (
    <>
      <nav
        className={
          scroll
            ? "navbar navbar-expand-lg bg-white sticky-top shadow-lg"
            : "navbar navbar-expand-lg"
        }>
        <div className="container-fluid px-lg-4">
          <div className="d-flex justify-content-between align-items-center py-2 px-lg-0 px-3 navbar-box mobile-nav">
            <a className="logo" href="/">
              <img src={logo} alt="logo" />
            </a>
            {/* {show == true ? (
              <button
                className="navbar-toggler"
                type="button"
                aria-label="Toggle navigation"
                onClick={() => setShowNavbar(false)}>
                <span className="icon-bar">
                  <i className="fas fa-times"></i>
                </span>
              </button>
            ) : (
              <button
                className="navbar-toggler"
                type="button"
                aria-label="Toggle navigation"
                onClick={() => setShowNavbar(true)}>
                <span className="icon-bar">
                  <i className="fas fa-bars"></i>
                </span>
              </button>
            )} */}
            <button
              className="navbar-toggler"
              type="button"
              aria-label="Toggle navigation"
              onClick={setShowNavbar}>
              <span className="icon-bar">
                <i className="fas fa-bars"></i>
              </span>
            </button>
          </div>

          <div
            className={
              show
                ? "collapse navbar-collapse show"
                : "collapse navbar-collapse"
            }>
            <ul className="navbar-nav ml-auto d-none d-lg-flex">
              <li className="nav-item bordered bggrad rectangle">
                <a
                  className="nav-link"
                  href="/restaurants"
                  data-scroll-nav="0"
                  onClick={() => setShow(false)}>
                  Restaurants
                </a>
              </li>
              {/* <li className="nav-item bordered bggrad rectangle">
                <Link className="nav-link" to="/chefs" data-scroll-nav="0">
                  Chefs
                </Link>
              </li>
              <li className="nav-item bordered bggrad rectangle">
                <Link className="nav-link" to="/events" data-scroll-nav="0">
                  Events
                </Link>
              </li> */}
              <li className="nav-item bordered bggrad rectangle">
                <a
                  className="nav-link"
                  href="/about-grub-digest"
                  data-scroll-nav="0"
                  onClick={() => setShow(false)}>
                  About Grub Digest
                </a>
              </li>
              <li className="nav-item bordered bggrad rectangle">
                <a
                  className="nav-link"
                  href="/contact-us"
                  data-scroll-nav="4"
                  onClick={() => setShow(false)}>
                  Let's Connect
                </a>
              </li>
              {/* <li className="nav-item bordered bggrad rectangle">
                <Link className="nav-link" to="/login" data-scroll-nav="4">
                  Login
                </Link>
              </li> */}
              <li className="nav-item bordered bggrad rectangle ms-3">
                <Button
                  className="btn btn-danger btn-lg"
                  onClick={() => {
                    // handleFormShow();
                    window.location.href = "/list-your-food-business"
                    setCountry("");
                    setStates([]);
                    setState("");
                    setCities([]);
                    setCity("");
                    setShow(false);
                  }}>
                  List your Food Business
                </Button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
      <Offcanvas show={showNavbar} onHide={handleNavbar} placement="end">
        <Offcanvas.Header closeButton>
          <Offcanvas.Title></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <ul className="navbar-nav">
            <li className="nav-item bordered bggrad rectangle">
              <a
                className="nav-link p-2"
                href="/restaurants"
                data-scroll-nav="0"
                onClick={() => setShow(false)}>
                Restaurants
              </a>
            </li>
            {/* <li className="nav-item bordered bggrad rectangle">
                <Link className="nav-link p-2" to="/chefs" data-scroll-nav="0">
                  Chefs
                </Link>
              </li>
              <li className="nav-item bordered bggrad rectangle">
                <Link className="nav-link p-2" to="/events" data-scroll-nav="0">
                  Events
                </Link>
              </li> */}
            <li className="nav-item bordered bggrad rectangle">
              <a
                className="nav-link p-2"
                href="/about-grub-digest"
                data-scroll-nav="0"
                onClick={() => setShow(false)}>
                About Grub Digest
              </a>
            </li>
            <li className="nav-item bordered bggrad rectangle">
              <a
                className="nav-link p-2 mb-2"
                href="/contact-us"
                data-scroll-nav="4"
                onClick={() => setShow(false)}>
                Let's Connect
              </a>
            </li>
            {/* <li className="nav-item bordered bggrad rectangle">
                <Link className="nav-link" to="/login" data-scroll-nav="4">
                  Login
                </Link>
              </li> */}
            <li className="nav-item bordered bggrad rectangle">
              <Button
                className="btn btn-danger btn-lg"
                onClick={() => {
                  // handleFormShow();
                  window.location.href = "/list-your-food-business"
                  setCountry("");
                  setStates([]);
                  setState("");
                  setCities([]);
                  setCity("");
                  setShow(false);
                }}>
                List your Food Business
              </Button>
            </li>
          </ul>
        </Offcanvas.Body>
      </Offcanvas>
      <Offcanvas show={showFormModal} onHide={handleFormHide} placement="end">
        <Offcanvas.Header className="pb-0" closeButton>
          <Offcanvas.Title></Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className="pt-0">
          <div class="row">
            <div class="col-12 text-center mb-4 small-view-padding">
              <SignUpImage />
              <h4 class="mb-2">Sign up</h4>
              <h6 className="extra-text title-right signup-phn-view">
                Add your <span>Food Business</span> to Grub Digest.
              </h6>
            </div>
          </div>
          <Form>
            <FloatingLabel
              controlId="floatingInput"
              label="Contact Person"
              className="mb-3">
              <Form.Control
                type="text"
                placeholder="Contact Person"
                onChange={(e) => {
                  setContactPerson(e.target.value);
                }}
              />
            </FloatingLabel>
            <FloatingLabel
              controlId="floatingInput"
              label="Restaurant Name"
              className="mb-3">
              <Form.Control
                type="text"
                placeholder="Restaurant Name"
                onChange={(e) => {
                  setRestaurantName(e.target.value);
                }}
              />
            </FloatingLabel>
            <FloatingLabel
              controlId="floatingInput"
              label="Contact Number"
              className="mb-3">
              <Form.Control
                type="text"
                placeholder="Contact Number"
                onChange={(e) => {
                  setMobileNumber(e.target.value);
                }}
              />
            </FloatingLabel>
            <FloatingLabel
              controlId="floatingInput"
              label="Email"
              className="mb-3">
              <Form.Control
                type="text"
                placeholder="Email"
                onChange={(e) => {
                  setEmail(e.target.value);
                }}
                onBlur={(e) => {
                  PostApiCall.postRequest({ emailAddress: e.target.value }, "getEmailAvailability").then(
                    (resultcategory) =>
                      resultcategory.json().then((obj) => {
                        if (resultcategory.status == 200 || resultcategory.status == 201) {
                          if (obj.message[0].emailcount > 0) {
                            setCheckEmail("No")
                          } else {
                            setCheckEmail("Yes")
                          }
                        }
                      })
                  );
                }}
              />
            </FloatingLabel>
            {email != "" ?
              <div
                className="mb-3">
                {checkEmail == "No" ?
                  <p className="text-danger">Email already registered.</p>
                  :
                  <p className="text-success">Email address available.</p>
                }
              </div> : <div
                className="mb-3"></div>
            }
            <FloatingLabel
              controlId="floatingInput"
              label="Location"
              className="mb-3">
              <Form.Control
                type="text"
                placeholder="Location"
                onChange={(e) => {
                  setAddress(e.target.value);
                }}
              />
            </FloatingLabel>
            <FloatingLabel
              className="mt-3 w-100"
              controlId="floatingSelect"
              label="Country">
              <Form.Select
                aria-label="Country"
                value={country}
                onChange={(e) => {
                  setCountry(e.target.value);
                  PostApiCall.postRequest(
                    { countryid: e.target.value },
                    "getState"
                  ).then((resultcategory) =>
                    resultcategory.json().then((obj) => {
                      if (
                        resultcategory.status == 200 ||
                        resultcategory.status == 201
                      ) {
                        setStates(obj.message);
                      }
                    })
                  );
                }}>
                <option>Select Country</option>
                {countries.length > 0 ? (
                  countries
                    .filter((data) => {
                      if (data.value == 101) {
                        return data;
                      }
                    })
                    .map((country, i) => {
                      return (
                        <option value={country.value}>{country.label}</option>
                      );
                    })
                ) : (
                  <></>
                )}
              </Form.Select>
            </FloatingLabel>
            <FloatingLabel
              className="mt-3 w-100"
              controlId="floatingSelect"
              label="State">
              <Form.Select
                aria-label="State"
                value={state}
                onChange={(e) => {
                  setState(e.target.value);
                  getCities(e.target.value);
                }}>
                <option>Select State</option>
                {states.length > 0 ? (
                  states.map((state, i) => {
                    return <option value={state.value}>{state.label}</option>;
                  })
                ) : (
                  <></>
                )}
              </Form.Select>
            </FloatingLabel>
            <FloatingLabel
              className="mt-3 w-100"
              controlId="floatingSelect"
              label="City">
              <Form.Select
                aria-label="City"
                value={city}
                onChange={(e) => {
                  setCity(e.target.value);
                }}>
                <option>Select City</option>
                {cities.length > 0 ? (
                  cities.map((city, i) => {
                    return <option value={city.value}>{city.label}</option>;
                  })
                ) : (
                  <></>
                )}
              </Form.Select>
            </FloatingLabel>
            <div className="d-flex justify-content-end align-items-center mt-3">
              {checkEmail == "Yes" ?
                <Button
                  onClick={() => {
                    if (contactPerson != "") {
                      if (restaurantName != "") {
                        if (mobileNumber != "") {
                          if (mobileNumber.length == 10) {
                            if (email != "") {
                              if (
                                /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                                  email
                                )
                              ) {
                                if (address != "") {
                                  Notiflix.Loading.dots("Please Wait")
                                  PostApiCall.postRequest(
                                    {
                                      contactPerson: contactPerson,
                                      companyName: restaurantName,
                                      address: address,
                                      country: country,
                                      state: state,
                                      city: city,
                                      mobileNumber: mobileNumber,
                                      email: email,
                                    },
                                    "addRestaurant"
                                  ).then((results) =>
                                    results?.json().then((obj1) => {
                                      if (
                                        results.status == 200 ||
                                        results.status == 201
                                      ) {
                                        if (obj1.message.length != 0) {
                                          localStorage.setItem(
                                            "restaurantid",
                                            obj1.message[0].resturantid
                                          );
                                          setCountry("");
                                          setState("");
                                          setCity("");
                                        }
                                        Notiflix.Loading.remove();
                                        setShowThankyouModal(true);
                                        handleFormHide();
                                      }
                                    })
                                  );
                                } else {
                                  Notiflix.Notify.failure("Enter Location");
                                }
                              } else {
                                Notiflix.Notify.failure("Enter Valid Email");
                              }
                            } else {
                              Notiflix.Notify.failure("Enter Email");
                            }
                          } else {
                            Notiflix.Notify.failure("Enter Valid Contact Number");
                          }
                        } else {
                          Notiflix.Notify.failure("Enter Contact Number");
                        }
                      } else {
                        Notiflix.Notify.failure("Enter Restaurant Name");
                      }
                    } else {
                      Notiflix.Notify.failure("Enter Contact Person.");
                    }
                  }}
                  className="btn btn-danger w-50"
                >
                  Continue
                </Button>
                : <Button className="btn btn-danger w-50" disabled={true}
                >
                  Continue
                </Button>}
            </div>

          </Form>
        </Offcanvas.Body>
      </Offcanvas>
      <div className="nav-item bordered bggrad rectangle d-md-none mobile-list-business-btn"
        style={{
          display: window.location.pathname == "/list-your-food-business" ? "none" : "block"
        }}>
        <Button
          className="btn btn-danger btn-lg"
          onClick={() => {
            // handleFormShow();
            window.location.href = "/list-your-food-business"
            setCountry("");
            setStates([]);
            setState("");
            setCities([]);
            setCity("");
            setShow(false);
          }}>

          List your Food Business
        </Button>
      </div>
      <ThankyouOffcanvas showOffcanvas={showThankyouModal} setShowThankyouModal={setShowThankyouModal} email={email} />
    </>
  );
}

export default Navbar;
