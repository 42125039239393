import React from "react";
import TenTwentyTwo from "../../assets/images/featured_restaurants/TenTwentyTwo.png";
import MenucardOne from "../../assets/images/menu_card_1.jpg";
import { IoReload, IoStar, IoStarHalfOutline, IoStarOutline } from "react-icons/io5";
import { TfiYoutube } from "react-icons/tfi";
import { Link } from "react-router-dom";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import "react-tabs/style/react-tabs.css";
import "./ChefInfo.css";
import { FaFacebookF, FaInstagram, FaLinkedinIn, FaPhoneAlt, FaPhoneVolume, FaPinterestP, FaPinterestSquare, FaWhatsapp } from "react-icons/fa";
import { MdDirections } from "react-icons/md";
import { BiLike } from "react-icons/bi";
import American from "../../assets/images/cuisines/American.png";
import karaoke from "../../assets/images/restaurant_facilities/karaoke.png";
import LiveShows from "../../assets/images/restaurant_facilities/live_show.png";
import RomanticDining from "../../assets/images/restaurant_facilities/romantic_dining.png";
import Smoking from "../../assets/images/restaurant_facilities/smoking.png";
import LiveMusic from "../../assets/images/restaurant_facilities/live_music.png";
import Wifi from "../../assets/images/restaurant_facilities/wifi.png";
import DJ from "../../assets/images/restaurant_facilities/dj.png";
import Takeaway from "../../assets/images/restaurant_facilities/takeaway.png";
import HomeDelivery from "../../assets/images/restaurant_facilities/home_delivery.png";
import OutdoorDining from "../../assets/images/restaurant_type/outdoor_dining.png";
import Breakfast from "../../assets/images/restaurant_facilities/breakfast.png";
import BuffetBrunch from "../../assets/images/restaurant_type/buffet_brunch.png";
import IndoorDining from "../../assets/images/restaurant_type/fine_dining.png";
import PubsBars from "../../assets/images/restaurant_type/pubs_bars.png";
import RoofTop from "../../assets/images/restaurant_type/rooftop.png";
import PetFriendly from "../../assets/images/restaurant_facilities/pet_friendly.png";
import FamilyFriendly from "../../assets/images/restaurant_facilities/family_friendly.png";
import SportsScreening from "../../assets/images/restaurant_facilities/sports_screening.png";
import ValetParking from "../../assets/images/restaurant_facilities/valet_parking.png";
import DigitalPayments from "../../assets/images/restaurant_facilities/digital_payments.png";
import CreditCards from "../../assets/images/restaurant_facilities/credit_cards.png";

function Restaurant() {
  const responsive = {
    superLargeDesktop: {
      // the naming can be any, depends on you.
      breakpoint: { max: 4000, min: 3000 },
      items: 2,
    },
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 2,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  const featuredRestaurant = [
    {
      image: TenTwentyTwo,
      name: "Ten Twenty Two",
      rating: "4.1",
      location: "DDA Market, New Delhi",
      cuisine: "European, Asian, Japanese, Pasta, Beverages, Desserts",
      minAmount: "1700",
      workingHours: "Open now 12noon – 2am (Today)",
      like: "1k"
    },
  ];

  const restaurantCuisines = [
    "Mediterranean",
    "Italian",
    "Arabian",
    "Mughlai",
    "Modern Indian",
    "Seafood",
    "Salad",
    "Desserts",
  ];

  const restaurantFacilities = [
    {
      image: HomeDelivery,
      name: "Home Delivery",
    },
    {
      image: Takeaway,
      name: "Takeaway",
    },
    {
      image: RoofTop,
      name: "Rooftop",
    },
    {
      image: OutdoorDining,
      name: "Outdoor",
    },
    {
      image: IndoorDining,
      name: "Indoor",
    },
    {
      image: PubsBars,
      name: "Bar",
    },
    {
      image: Breakfast,
      name: "Breakfast",
    },
    {
      image: LiveMusic,
      name: "Live Music",
    },
    {
      image: SportsScreening,
      name: "Sport Screening",
    },
    {
      image: karaoke,
      name: "Karaoke",
    },
    {
      image: LiveShows,
      name: "Live Shows",
    },
    {
      image: Smoking,
      name: "Smoking Area",
    },
    {
      image: RomanticDining,
      name: "Romantic Dining",
    },
    {
      image: BuffetBrunch,
      name: "Buffet",
    },
    {
      image: DJ,
      name: "DJ",
    },
    {
      image: FamilyFriendly,
      name: "Family Friendly",
    },
    {
      image: PetFriendly,
      name: "Pet Friendly",
    },
    {
      image: ValetParking,
      name: "Valet Parking",
    },
    {
      image: Wifi,
      name: "Wifi",
    },
    {
      image: DigitalPayments,
      name: "Digital Pay",
    },
    {
      image: CreditCards,
      name: "Credit Cards",
    },
  ];

  return (
    <section className="services section-padding pb-70" data-scroll-index="3">
      <div className="container-fluid px-lg-4 cuisines">
        <div className="row">
          <div className="col-lg-12">
            <Carousel
              swipeable={true}
              draggable={true}
              showDots={true}
              responsive={responsive}
              ssr={true} // means to render carousel on server-side.
              infinite={true}
              keyBoardControl={true}
              containerClass="carousel-container"
              dotListClass="custom-dot-list-style"
              itemClass="pb-4"
            >
              <div className="mr-lg-2">
                <img
                  src={TenTwentyTwo}
                  className="img-fluid w-100 rounded"
                  alt=""
                />
              </div>
              <div className="mr-lg-2">
                <img
                  src={TenTwentyTwo}
                  className="img-fluid w-100 rounded"
                  alt=""
                />
              </div>
              <div className="mr-lg-2">
                <img
                  src={TenTwentyTwo}
                  className="img-fluid w-100 rounded"
                  alt=""
                />
              </div>
              <div className="mr-lg-2">
                <img
                  src={TenTwentyTwo}
                  className="img-fluid w-100 rounded"
                  alt=""
                />
              </div>
            </Carousel>
          </div>

          {featuredRestaurant.map((data) => (
            <div className="col-lg-12 position-relative">
              <div className="row">
                <div className="col-lg-9">
                  <div className="p-0">
                    <h5 className="mb-2">{data.name}</h5>
                    <p className="mb-2">{data.cuisine}</p>
                    <div className="d-lg-flex">
                      <a href="">
                        <div className="like-block">
                          <span className="mr-2">{data.like}</span>
                          <BiLike className="rating-star" />
                        </div>
                      </a>
                      <a href="">
                        <div className="rating-block">
                          <span className="mr-2">{data.rating}</span>
                          <IoStar className="rating-star" />
                          <IoStar className="rating-star" />
                          <IoStar className="rating-star" />
                          <IoStarHalfOutline className="rating-star" />
                          <IoStarOutline className="rating-star" />
                        </div>
                      </a>
                    </div>
                    <p className="mb-2">{data.location}</p>
                    <p className="mb-2">{data.workingHours}</p>
                  </div>
                </div>
                <div className="col-lg-3 pr-4">
                  <div className="d-flex justify-content-end align-items-center social-block">
                    <a href="">
                      <FaFacebookF className="social-share" />
                    </a>
                    <a href="">
                      <FaInstagram className="social-share" />
                    </a>
                    <a href="">
                      <TfiYoutube className="social-share" />
                    </a>
                    <a href="">
                      <FaPinterestP className="social-share" />
                    </a>
                    <a href="">
                      <FaLinkedinIn className="social-share" />
                    </a>
                    <a href="" className="bg-light d-flex">
                      <FaWhatsapp className="social-share action-btn" />
                      <span>WhatsApp Chat</span>
                    </a>
                    <a href="" className="bg-light d-flex">
                      <FaPhoneVolume className="social-share action-btn rotate-icon" />
                      <span>Call Restaurant</span>
                    </a>
                  </div>
                  <div>
                    <a
                      href=""
                      className="btn btn-danger btn-block btn-lg mt-lg-5 d-block"
                    >
                      Order Online
                    </a>
                  </div>
                </div>
              </div>
            </div>
          ))}
          <div className="col-lg-12">
            <div className="mb-4">
              <p className="tab-section-title">Menu</p>
              <div className="row">
                <div className="col-3">
                  <a href="" className="d-block overflow-hidden">
                    <img
                      src={MenucardOne}
                      className="img-fluid w-100 mb-2 rounded"
                    />
                  </a>
                  <p className="font-weight-bold">Food Menu</p>
                  <p className="">10 Pages</p>
                </div>
                <div className="col-3">
                  <a href="" className="d-block">
                    <img
                      src={MenucardOne}
                      className="img-fluid w-100 mb-2 rounded"
                    />
                  </a>
                  <p className="font-weight-bold">Bar Menu</p>
                  <p className="">4 Pages</p>
                </div>
                <div className="col-3">
                  <a href="" className="d-block">
                    <img
                      src={MenucardOne}
                      className="img-fluid w-100 mb-2 rounded"
                    />
                  </a>
                  <p className="font-weight-bold">Beverages</p>
                  <p className="">5 Pages</p>
                </div>
              </div>
            </div>
            <div className="mb-4">
              <p className="tab-section-title">Cuisines</p>
              <div className="col-12">
                <div className="row">
                  {restaurantCuisines.map((data) => (
                    <Link className="btn btn-outline-danger mr-3 mb-3">
                      {data}
                    </Link>
                  ))}
                </div>
              </div>
            </div>
            <div className="mb-4">
              <p className="tab-section-title">Average Cost</p>
              <div className="row">
                <div className="col-12 average-cost">
                  <p className="font-weight-bold">
                    ₹3,000 for two people (approx.) Without alcohol
                  </p>
                  <p className="small">
                    Exclusive of applicable taxes and charges, if any
                  </p>
                  <p className="font-weight-bold">
                    ₹395 for a pint of beer (approx.)
                  </p>
                  <p className="small">How do we calculate cost for two?</p>
                  <p>Digital payments accepted</p>
                </div>
              </div>
            </div>
            <div className="mb-4">
              <p className="tab-section-title">Facilities</p>
              <div className="hero">
                <div className="row">
                  {restaurantFacilities.map((data) => (
                    <div className="col-lg-2 col-6 restaurant-type-wrap px-3">
                      <Link
                        to=""
                        className="d-block position-relative overflow-hidden"
                      >
                        <div className="item min-height-auto">
                          <img
                            src={data.image}
                            className="services-icon"
                            alt=""
                          />
                          <h6 className="mb-0">{data.name}</h6>
                        </div>
                      </Link>
                    </div>
                  ))}
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12">
                <p className="tab-section-title">Photo Gallery</p>
              </div>
              <div className="col-2 mb-4">
                <a href="#" className="d-block">
                  <img src={American} className="img-fluid w-100 rounded"></img>
                </a>
              </div>
              <div className="col-2">
                <img src={American} className="img-fluid w-100 rounded"></img>
              </div>
              <div className="col-2">
                <img src={American} className="img-fluid w-100 rounded"></img>
              </div>
              <div className="col-2">
                <img src={American} className="img-fluid w-100 rounded"></img>
              </div>
              <div className="col-2">
                <img src={American} className="img-fluid w-100 rounded"></img>
              </div>
            </div>
            <div className="quick-contact">
              <div className="row">
                <div className="col-lg-6">
                  <p className="tab-section-title">Contact</p>
                  <p className="contact-restaurant-name mb-2">Ten Twenty Two</p>
                  <div className="contact-address contact-detail">
                    <div>Address</div>
                    <div>
                      <p className="mb-2 font-2x address-one">
                        14A, 2nd Floor, Ireo Grand View High Street,
                      </p>
                      <p className="mb-2 font-2x address-two">
                        Gold Course Extension Road, Sector 56,
                      </p>
                      <p className="mb-2 font-2x address-three">
                        Gurgaon - 110024, INDIA
                      </p>
                    </div>
                  </div>
                  <div className="contact-detail">
                    <div className="">Mobile</div>
                    <div className="">
                      <a
                        href="tel:+919999999999"
                        className="d-block text-danger mb-2"
                      >
                        +91 99999 99999
                      </a>
                      <a
                        href="tel:+919999999999"
                        className="d-block text-danger mb-2"
                      >
                        +91 99999 99999
                      </a>
                    </div>
                  </div>
                  <div className="contact-detail">
                    <div className="">Email</div>
                    <div className="">
                      <a
                        href="mailto:restaurant@gmail.com"
                        className="d-block text-danger mb-2"
                      >
                        restaurant@gmail.com
                      </a>
                    </div>
                  </div>
                  <div className="contact-detail">
                    <div className="">Website</div>
                    <div className="">
                      <a href="#" className="d-block text-danger mb-2">
                        www.restaurant.com
                      </a>
                    </div>
                  </div>
                </div>
                <div className="col-lg-6">
                  <p className="tab-section-title">Location</p>
                  <iframe
                    src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d224346.54004786978!2d77.04417384861837!3d28.527252739279792!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x390cfd5b347eb62d%3A0x52c2b7494e204dce!2sNew%20Delhi%2C%20Delhi!5e0!3m2!1sen!2sin!4v1687605976498!5m2!1sen!2sin"
                    width={"100%"}
                    height={"300px"}
                    style={{ border: "0" }}
                  ></iframe>
                  <a href="" className="btn btn-outline-danger">
                    <div className="d-flex align-items-center">
                      <MdDirections className="text-danger font-special" />{" "}
                      <span className="ml-2">Direction</span>
                    </div>
                  </a>
                </div>
              </div>
            </div>
            {/* <span className="font-weight-bold">
                  ₹ {data.minAmount} for two
                </span> */}
          </div>
        </div>
      </div>
    </section>
  );
}

export default Restaurant;
