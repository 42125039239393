import React, { useState } from "react";
import {
  Button,
  Card,
  Col,
  Container,
  FloatingLabel,
  Form,
  Row,
} from "react-bootstrap";

function Login() {
  const [mobileScreen, setMobileScreen] = useState(true);
  const [otpScreen, setOtpScreen] = useState(false);

  const getOtp = () => {
    setMobileScreen(false);
    setOtpScreen(true);
  }

  const getMobile = () => {
    setMobileScreen(true);
    setOtpScreen(false);
  };

  return (
    <section>
      <Container className={mobileScreen != false ? "d-block" : "d-none"}>
        <Row>
          <div className="col-12 text-center mb-5">
            <h3 className="mb-2 fw-bold">Welcome to Grub Digest!</h3>
            <h6 className="mb-2">
              To continue your Login, please enter your mobile number and get an
              OTP.
            </h6>
          </div>
        </Row>
        <Row className="justify-content-center align-items-center">
          <Col lg={4}>
            <Card>
              <Card.Body>
                <Form>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Mobile Number"
                    className="mb-3"
                  >
                    <Form.Control
                      type="mobile"
                      placeholder="9999999999"
                      maxlength="10"
                    />
                  </FloatingLabel>
                  <Button variant="danger" className="w-100" onClick={getOtp}>
                    Send OTP
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <div className="col-12 text-center mb-5">
            <h6 className="my-4 mb-2">
              A 4-digit OTP will be sent via SMS to verify your mobile number!
            </h6>
          </div>
        </Row>
      </Container>
      <Container className={otpScreen != false ? "d-block" : "d-none"}>
        <Row className="justify-content-center align-items-center">
          <div className="col-6 text-center mb-5">
            <h3 className="mb-2 fw-bold">OTP Verification</h3>
            <h6 className="mb-2">
              To continue your Verification, please enter OTP sent to your
              number
            </h6>
            <h6>+91 9999999999</h6>
          </div>
        </Row>
        <Row className="justify-content-center align-items-center">
          <Col lg={4}>
            <Card>
              <Card.Body>
                <Form>
                  <Row className="mb-3">
                    <Col>
                      <Form.Control
                        type="password"
                        className="text-center"
                        maxlength="1"
                        placeholder="0"
                      />
                    </Col>
                    <Col>
                      <Form.Control
                        type="password"
                        className="text-center"
                        maxlength="1"
                        placeholder="0"
                      />
                    </Col>
                    <Col>
                      <Form.Control
                        type="password"
                        className="text-center"
                        maxlength="1"
                        placeholder="0"
                      />
                    </Col>
                    <Col>
                      <Form.Control
                        type="password"
                        className="text-center"
                        maxlength="1"
                        placeholder="0"
                      />
                    </Col>
                  </Row>
                  <Button
                    variant="danger"
                    className="w-100"
                    onClick={getMobile}
                  >
                    Verify OTP
                  </Button>
                </Form>
              </Card.Body>
            </Card>
          </Col>
        </Row>
        <Row>
          <div className="col-12 text-center mb-5">
            <h6 className="my-4 mb-2">
              Didn't receive the OTP?{" "}
              <a href="" className="grub-red text-decoration-none">
                Resend OTP
              </a>
            </h6>
          </div>
        </Row>
      </Container>
    </section>
  );
}

export default Login;
