import Notiflix from "notiflix";
import React, { useEffect, useState } from "react";
import {
  Card, Col, Container, FloatingLabel,
  Form, Row
} from "react-bootstrap";
import { BsSliders } from "react-icons/bs";
import {
  FaSearch
} from "react-icons/fa";
import { GiPlainCircle } from "react-icons/gi";
import { LuVegan } from "react-icons/lu";
import { Link, useParams } from "react-router-dom";
import PostApiCall from "../../API/POSTAPI";
import chefsCap from "../../assets/images/chefscap.svg";
import NonVegetarian from "../../assets/images/nonveg_icon.png";
import Vegetarian from "../../assets/images/veg_icon.png";
import logo from "../../dish.png"

function DigitalMenu() {
  let params = useParams();
  const [scroll, setScroll] = useState(false);
  const [searchText, setSearchText] = useState("");
  const [cuisines, setcuisines] = useState([]);
  const [active, setActive] = useState(null);
  const [allItemMasterOfflineData, setAllItemMasterOfflineData] = useState([]);
  const [restaurantLogo, setRestaurantLogo] = useState("");
  const [restaurantName, setRestaurantName] = useState("");
  const [restaurantDescription, setRestaurantDescription] = useState("");

  useEffect(() => {
    if (window.pageYOffset > 750) {
      setScroll("scroll");
    } else {
      window.addEventListener("scroll", () => {
        setScroll(window.scrollY > 750);
      });
    }
  });

  const offerBanners = {
    desktop: {
      breakpoint: { max: 3000, min: 1024 },
      items: 2,
    },
    tablet: {
      breakpoint: { max: 1024, min: 464 },
      items: 1,
    },
    mobile: {
      breakpoint: { max: 464, min: 0 },
      items: 1,
    },
  };

  useEffect(() => {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        whereClause: `where fld_client_id = ${params.id}`,
      },
      "getItemDetails"
    ).then((resultdes) =>
      resultdes.json().then((obj2) => {
        if (obj2.message != 0) {
          setAllItemMasterOfflineData(obj2.message);
          setcuisines(obj2.message)
        } else {
          Notiflix.Loading.remove();
          Notiflix.Notify.failure("No Records Exits");
        }
      })
    );
    Notiflix.Loading.remove();
    PostApiCall.postRequest(
      {
        whereClause: `where fld_client_id= ${params.id}`,
      },
      "getReataurantData"
    ).then((results) =>
      results?.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          if (obj1.message.length > 0) {
            setRestaurantLogo(obj1.message[0]?.fld_logo)
            setRestaurantName(obj1.message[0]?.fld_restaurantname)


          }
          Notiflix.Loading.remove();
        }
      })
    );
  }, [params.id]);
  const downloadQR = () => {
    const canvas = document.getElementById("qr-code");
    const pngUrl = canvas
      .toDataURL("image/png")
      .replace("image/png", "image/octet-stream");
    let downloadLink = document.createElement("a");
    downloadLink.href = pngUrl;
    downloadLink.download = "restaurant-qr.png";
    document.body.appendChild(downloadLink);
    downloadLink.click();
    document.body.removeChild(downloadLink);
  };

  return (
    <>
      <section className="services pt-3">
        {/* <div>
          <a download href={qrCodeUrl}>
            <img src={qrCodeUrl} />
          </a>
        </div> */}

        {<Container fluid>
          <Row className="justify-content-center mb-lg-4">
            <Col lg={2} xs={4}>
              <img
                src={restaurantLogo != null ? restaurantLogo : logo}
                className="img-fluid w-100 border rounded"
              />
            </Col>
            <Col lg={10} xs={8}>
              <h1 className="fw-bold text-danger">{restaurantName}</h1>
              <h4 className="fw-bold">Digital Menu</h4>
              <p className="d-lg-block d-none">
                We are a passionate foodie group that helps you in finding the
                right place to dine out or order food based on your cravings.
              </p>
            </Col>
            <Col lg={12} className="d-lg-none d-block mt-2 mb-2">
              <p className="">
                We are a passionate foodie group that helps you in finding the
                right place to dine out or order food based on your cravings.
              </p>
            </Col>
          </Row>
        </Container>}
      </section>

      {/* <section>
        <Container fluid>
          <Carousel
            swipeable={true}
            draggable={true}
            showDots={true}
            responsive={offerBanners}
            ssr={true} // means to render carousel on server-side.
            infinite={true}
            autoPlay={true}
            autoPlaySpeed={3000}
          >
            <div className="text-center me-lg-3 mx-0">
              <div className="offer-image">
                <img className="img-fluid" alt="" src={promoBannerOne} />
              </div>
            </div>

            <div className="text-center me-lg-3 mx-0">
              <div className="offer-image">
                <img className="img-fluid" alt="" src={promoBannerTwo} />
              </div>
            </div>
          </Carousel>
        </Container>
      </section> */}

      <section
        className={
          scroll ? "pt-3 sticky-search-bar digital-menu" : "pt-3 digital-menu"
        }
      >
        <Container fluid>
          <Card className={scroll ? "" : "shadow-search"}>
            <Card.Body className="py-0">
              <Row className="align-items-center">
                <Col
                  lg={6}
                  className="py-3 d-flex justify-content-between align-content-center gap-2"
                >
                  <div className="flex-div">
                    <Form>
                      <FloatingLabel
                        controlId="floatingName"
                        label="Search for a cuisine or a dish"
                        className="mb-lg-0 mb-0 search-box"
                      >
                        <Form.Control
                          type="text"
                          value={searchText}
                          onChange={(e) => {
                            const removeFav = allItemMasterOfflineData;
                            setcuisines(
                              removeFav.filter((product) =>
                                product.search_field
                                  .toLowerCase()
                                  .includes(e.target.value)
                              )
                            );
                            setSearchText(e.target.value);
                          }}
                          placeholder="Search for a cuisine or a dish"
                        />
                        <FaSearch className="search-icon" />
                      </FloatingLabel>
                    </Form>
                  </div>
                  {/* <div className="mt-3 mt-lg-0">
                    <a href="" className="item-category-filter nonveg filter">
                      <BsSliders />
                    </a>
                  </div> */}
                </Col>
                <Col lg={6}>
                  <Row className="food-type-filter-row">
                    <button
                      type="button"
                      className={`item-category-filter veg ${active == "Vegetarian" && "active"
                        }`}
                      onClick={() => {
                        setActive("Vegetarian");
                        const removeFav = allItemMasterOfflineData;
                        console.log(removeFav);
                        setcuisines(
                          removeFav.filter(
                            (product) => product.FLD_Food_Type_name === "Veg"
                          )
                        );
                        setSearchText("");
                      }}
                    >
                      {/* <img src={Vegetarian} className="recipe-type" />  */}
                      <GiPlainCircle />
                      Veg
                    </button>
                    <button
                      type="button"
                      className={`item-category-filter nonveg ${active == "NonVegetarian" && "active"
                        }`}
                      onClick={() => {
                        setActive("NonVegetarian");
                        const removeFav = allItemMasterOfflineData;
                        setcuisines(
                          removeFav.filter(
                            (product) =>
                              product.FLD_Food_Type_name === "Non Veg"
                          )
                        );
                        setSearchText("");
                      }}
                    >
                      {/* <img src={NonVegetarian} className="recipe-type" />  */}
                      <GiPlainCircle />
                      Non Veg
                    </button>
                    <button
                      className={`item-category-filter vegan ${active == "Vegan" && "active"
                        }`}
                      onClick={() => {
                        setActive("Vegan");
                        const removeFav = allItemMasterOfflineData;
                        console.log(removeFav);
                        setcuisines(
                          removeFav.filter(
                            (product) => product.FLD_Food_Type_name === "Vegan"
                          )
                        );
                        setSearchText("");
                      }}
                    >
                      {/* <img src={Vegan} className="recipe-type" /> */}
                      <LuVegan />
                      Vegan
                    </button>
                  </Row>
                </Col>
              </Row>
            </Card.Body>
          </Card>
        </Container>
      </section>

      <section className="my-4 digital-menu">
        <Container fluid>
          <Row>
            {cuisines.length > 0 ?
              cuisines?.map((item) => {
                return (
                  <Col lg={6} className="mb-3">
                    <Card className="shadow-sm">
                      <Card.Body>
                        <Col className="item-card-wrapper px-0">
                          <div className="item-card">
                            <div className="recipe-img-wrapper">
                              <Link>
                                <img
                                  src={item.FLD_Image}
                                  className="img-fluid recipe-img order-lg-1 order-2"
                                  alt=""
                                />
                              </Link>
                            </div>
                            <div className="w-100 px-lg-4 px-0 py-lg-0 py-2 order-lg-2 order-1">
                              <div>
                                <div>
                                  <p className="recipe-best">
                                    {item.FLD_Food_Type == 2 ? (
                                      <img
                                        src={NonVegetarian}
                                        className="recipe-type"
                                        alt="nonveg"
                                      />
                                    ) : (
                                      <img
                                        src={Vegetarian}
                                        className="recipe-type"
                                        alt="veg"
                                      />
                                    )}
                                    {item.FLD_Best_Seller == "Yes" ? (
                                      <span className="best-seller">
                                        Best Seller
                                      </span>
                                    ) : (
                                      <></>
                                    )}
                                    {item.FLD_Customisable == "Yes" ? (
                                      <span className="customisable">
                                        Customisable
                                      </span>
                                    ) : (
                                      <></>
                                    )}

                                    {item.FLD_Chefs_Choice == "Yes" ? (
                                      <img
                                        src={chefsCap}
                                        className="recipe-type"
                                        alt="Chef's Special"
                                      />
                                    ) : (
                                      <></>
                                    )}
                                  </p>
                                  <p className="recipe-name">
                                    <Link>{item.FLD_Name}</Link>
                                  </p>

                                  <p className="recipe-description">
                                    {item.FLD_Description}
                                  </p>
                                  <span className="ms-auto">
                                    {item.FLD_Discount_Price}
                                  </span>
                                </div>
                              </div>
                            </div>
                          </div>
                        </Col>
                      </Card.Body>
                    </Card>
                  </Col>
                );
              }) :
              <div className="d-flex justify-content-center align-items-center">
                Items You are Searching For is Not Available
              </div>}
          </Row>
        </Container>
      </section>
      <footer>
        <Container className="px-lg-1 mb-0">
          <Row className="justify-content-lg-center justify-content-center">
            <Col lg={6} className="">
              <p className="design text-lg-end text-end">
                <p class="text-center mb-lg-0 mb-0 text-white">
                  Powered by GrubDigest
                </p>
              </p>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default DigitalMenu;
