import Notiflix from 'notiflix';
import React, { useEffect, useState } from 'react';
import { Col, Row } from 'react-bootstrap';
import { Link } from 'react-router-dom';
import GetApiCall from '../../API/GETAPI';

function Chefs() {
  const [eventList, setEventList] = useState([]);

  useEffect(() => {
    GetApiCall.getRequest("getEvents").then((results) =>
      results?.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          if (obj1.message.length != 0) {
            setEventList(obj1.message);
          }
          Notiflix.Loading.remove();
        }
      })
    );
  }, []);
  return (
    <section className="services pt-lg-3 pb-70" data-scroll-index="3">
      <div className="container-fluid px-lg-4 cuisines">
        <div className="row">
          <div className="col-12 text-center mb-4">
            <h3 className="mb-2">Heading</h3>
            <h6 className="mb-2">Heading</h6>
          </div>
        </div>
        <Row>
          {eventList.map((val) => {
            return (
              <Col lg={3}>
                <Link to="/">
                  <img
                    src={val.fld_eventimage}
                    className="img-fluid w-100 rounded"
                    alt=""
                  />
                </Link>
              </Col>
            );
          })}
        </Row>
      </div>
    </section>
  );
}

export default Chefs;