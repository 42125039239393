import React from 'react';
import "./OrderFormRestaurant.css";

function OrderFormRestaurant() {
  return (
    <>
      <div className="book" id="print-recipt">
        <div className="page">
          <div className="subpage">
            <div className="header">
              <p>haldiram products pvt. ltd.</p>
              <p>
                <strong> restaurant service </strong>
              </p>
              <p className="mb-0">
                <strong> sac code: 9963210 </strong>
              </p>
            </div>
            <div className="contact-details">
              <p>
                haldiram - tilak nagar, 48/16, nazafgarh road, tilak nagar, new
                delhi
              </p>
              <p>phone number - 011-47685707</p>
              <p className="govt-info">CIN: UG2F3GDFD456TG09</p>
              <p className="govt-info">GSTIN: SG2F3GDFD456TG0T</p>
              <p>reg. off. 19 a shivaji marg, nazafgarh road</p>
              <p>new moti nagar delhi - 110015</p>
              <p className="website-info">website www.haldiram.com</p>
            </div>
            <div className="invoice-details">
              <p>[INVOICE]</p>
              <p className="left">Token No.: 1</p>
              <p className="left">
                <strong> bill no. 57611 | 23-09-2023 | 01:28:35 PM </strong>
              </p>
              <div>
                <table className="item-table">
                  <thead>
                    <tr>
                      <th colSpan="3">Description</th>
                      <th>Price</th>
                      <th>Qty</th>
                      <th>Amount</th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr>
                      <td colSpan="3">
                        6" cheez pizza
                        <br />
                        HSN:996331
                      </td>
                      <td>80.00</td>
                      <td>1.00</td>
                      <td>80.00</td>
                    </tr>
                    <tr>
                      <td colSpan="3">
                        6" cheez pizza
                        <br />
                        HSN:996331
                      </td>
                      <td>80.00</td>
                      <td>1.00</td>
                      <td>80.00</td>
                    </tr>
                    <tr>
                      <th colSpan="3">sub total</th>
                      <th colSpan="2">1.00</th>
                      <th>80.00</th>
                    </tr>
                    <tr>
                      <td colSpan="3">Add CGST 9%ON (80.00)</td>
                      <td colSpan="3">7.20</td>
                    </tr>
                    <tr>
                      <td colSpan="3">Add CGST 9%ON (80.00)</td>
                      <td colSpan="3">7.20</td>
                    </tr>
                    <tr>
                      <th colSpan="3">amount including GST</th>
                      <th colSpan="3">94.40</th>
                    </tr>
                    <tr>
                      <td colSpan="3">rounded amount read</td>
                      <td colSpan="3">94.00</td>
                    </tr>
                    <tr>
                      <td colSpan="3">amount in words</td>
                      <td colSpan="3">rupees ninety four only</td>
                    </tr>
                    <tr>
                      <th colSpan="3">Cash</th>
                      <th colSpan="3">94.00</th>
                    </tr>
                    <tr>
                      <td colSpan="3">rounding</td>
                      <td colSpan="3">-0.4</td>
                    </tr>
                    <tr>
                      <th colSpan="3">cashier name</th>
                      <th colSpan="3">Aman (E&OE)</th>
                    </tr>
                  </tbody>
                </table>
              </div>
              <p className="govt-info">thanks for visiting haldiram's</p>
              <p className="govt-info">have a nice day</p>
              <p className="govt-info">
                <strong> now buy online at haldiramsonline.com </strong>
              </p>
              <p className="govt-info">
                <strong> for any queries call +911147685219 </strong>
              </p>
              <p className="website-info">website www.haldiram.com</p>
            </div>
            <div className="barcode-details">
              <p>service tax not applicable</p>
              <p className="barcode"></p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default OrderFormRestaurant;