import Notiflix from "notiflix";
import React, { useContext, useEffect, useState } from "react";
import { BiSearchAlt } from "react-icons/bi";
import { IoRestaurantOutline } from "react-icons/io5";
import { PiBowlFoodFill } from "react-icons/pi";
import PostApiCall from "../../API/POSTAPI";
import ChefCard from "../../component/ChefCard/ChefCard";
import { store } from "../../Context/store";

function Chefs() {
  const [featuredRestaurant, setFeaturedRestaurant] = useState([]);
  const { chooseCity, setChooseCity } = useContext(store);
  useEffect(() => {
    setTimeout(() => {
      window.scrollTo({ top: 0, behavior: "smooth" });
    }, 0);
    PostApiCall.postRequest(
      {
        //cityId: chooseCity,
        cityId: 131687,
      },
      "getRestaurantMasterData"
    ).then((results) =>
      results?.json().then((obj1) => {
        if (results.status == 200 || results.status == 201) {
          if (obj1.message.length != 0) {
            setFeaturedRestaurant(obj1.message);
          }
          Notiflix.Loading.remove();
        }
      })
    );
  }, []);
  return (
    <section className="services pt-lg-3 pb-70" data-scroll-index="3">
      <div className="container-fluid px-lg-4 cuisines">
        <div className="row justify-content-center align-items-center mb-4">
          <div className="col-lg-9 search-section">
            <div className="card shadow text-center p-3 search-card">
              {/* <h4 className="extra-text mb-2">
                Good <span>Mood</span>, Find <span>Food</span>
              </h4> */}
              <form>
                <div className="form-row">
                  <div className="col-lg-4 col-12">
                    <h4 className="extra-text mb-lg-0 mb-2 no-text-shadow pt-1">
                      Good <span className="no-text-shadow">Mood</span>, Find{" "}
                      <span className="no-text-shadow">Food</span>
                    </h4>
                  </div>
                  <div className="col-lg-8 col-12 search-input">
                    <input
                      type="email"
                      className="form-control pl-5"
                      id="exampleFormControlInput1"
                      placeholder="Look for restaurants, cuisines or a dish"
                    />
                    <span className="search-icon">
                      <BiSearchAlt className="search-icon-svg" />
                    </span>
                  </div>
                </div>
              </form>
            </div>
            <div className="card shadow text-center ms-2 filter-card">
              <span className="search-icon rounded">
                <PiBowlFoodFill className="search-icon-svg" />
                <p className="filter-label">Cuisine</p>
              </span>
            </div>
            <div className="card shadow text-center ms-2 filter-card">
              <span className="search-icon rounded">
                <IoRestaurantOutline className="search-icon-svg" />
                <p className="filter-label">Restaurant</p>
              </span>
            </div>
          </div>
        </div>
        <div className="row">
          <div className="col-12 text-center mb-4">
            <h3 className="mb-2">Heading</h3>
            <h6 className="mb-2">Heading</h6>
          </div>
        </div>
        <div className="row">
          <ChefCard featuredRestaurant={featuredRestaurant} />
        </div>
      </div>
    </section>
  );
}

export default Chefs;
