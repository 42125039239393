import Notiflix from "notiflix";
import React, { useEffect, useState } from "react";
import { Button, Col, Row } from "react-bootstrap";
import { MdAddCircleOutline, MdOutlineCancel } from "react-icons/md";
import { useParams } from "react-router-dom";
import noImage from "../../assets/images/no-image.png";
import PostApiCall from "../../API/POSTAPI";
import "./ImageUpload.css";
import { Cropper } from "react-cropper";

const ImageUpload = ({ images, UploadApiUrl, SaveApiUrl, menuTypeId, menuTypeName }) => {
    let params = useParams();
    let schema = "grubdigest_master"
    let LoginDetail = JSON.parse(localStorage.getItem("LoginDetail"))
    let restaurantId = params.id
    const [selectImage, setSelectImage] = useState("")
    const [photos, setPhotos] = useState([]);
    const [imageurl, setImageurl] = useState("https://grubdigest.com/images/");
    const [updatedItemsData, setUpdatedItemsData] = useState(false)
    const [name, setName] = useState("");
    const [imageId, setimageId] = useState(null)
    const [outputFileName, setoutputFileName] = useState("");
    const [ImageApiUrl, setImageApiUrl] = useState("");
    const [src, setSrc] = useState(null);
    const [crop, setCrop] = useState({ aspect: 16 / 9 });
    const [image, setImage] = useState(null);
    const [output, setOutput] = useState(null);
    const [imageDataCover, setImageDataCover] = useState("")
    const [cropData, setCropData] = useState("#");
    const [croppedImage, setCroppedImage] = useState(null);
    const [croppedImageName, setCroppedImageName] = useState(null);


    const selectImagefile = (file) => {
        setSrc(URL.createObjectURL(file));
    };


    const ImgUpload = ({ onChange, src }) => (
        <label htmlFor="photo-upload" className="custom-file-upload fas w-100">
            <div className="img-wrap img-upload upload-image-component">
                <img
                    htmlFor="photo-upload"
                    src={src}
                    style={{ width: "auto", height: "100%", borderRadius: "5%", objectFit: "cover" }}
                />
            </div>
            <input accept="image/*" id="photo-upload" type="file" onChange={onChange} />
        </label>
    );
    const ImgUploadNew = ({ onChange, src }) => (
        <div className="col-lg-3">
            <label htmlFor="photo-upload-new" className="custom-file-upload fas gallery-img-upload">
                <div className="upload-img img-upload">
                    <div className="upload-image rounded" style={{ width: "80px", height: "80px", borderRadius: "5%" }}>
                        <div className="text-center">
                            <div className="text-dark cursor-pointer">
                                <MdAddCircleOutline className="upload-imge-btn" />
                            </div>
                        </div>
                    </div>
                </div>
                <input accept="image/*" id="photo-upload-new" type="file" onChange={onChange} />
            </label>
        </div>
    );
    useEffect(() => {
        // setPhotos(images)
        setImageApiUrl(UploadApiUrl);
        if (menuTypeName == "gallery images") {
            getRestaurantImages();
        } else {
            getRestaurantMenuImages()
        }
    }, [])
    const DeleteImage = (id, type) => {
        Notiflix.Loading.dots("");
        PostApiCall.postRequest(
            {
                id: id,
                deletefrom: type
            }
            ,
            "deleteRestaurantImage").then((results) =>
                results?.json().then((obj1) => {
                    if (results.status == 200 || results.status == 201) {
                        if (obj1?.message?.length != 0) {
                            if (menuTypeName == "gallery images") {
                                getRestaurantImages();
                                Notiflix.Notify.success("Image Successfully deleted")
                                Notiflix.Loading.remove();
                            } else {
                                getRestaurantMenuImages()
                                Notiflix.Notify.success("Image Successfully deleted")
                                Notiflix.Loading.remove();
                            }
                        }
                        Notiflix.Loading.remove();
                    }
                })
            );
    }
    const getRestaurantImages = () => {
        PostApiCall.postRequest(
            {
                whereClause: `where fld_restaurant_id = ${restaurantId} and fld_type = '${menuTypeName}'`,
                columns: "*"
            }
            ,
            "getRestaurantImages").then((results) =>
                results?.json().then((obj1) => {
                    if (results.status == 200 || results.status == 201) {
                        if (obj1.message.length != 0) {
                            setPhotos(obj1.message)
                        }
                        Notiflix.Loading.remove();
                    }
                })
            );
    }
    const getRestaurantMenuImages = () => {
        PostApiCall.postRequest(
            {
                whereClause: `where fld_restaurant_id = ${restaurantId} and fld_menu = '${menuTypeName}'`,
                columns: "*"
            }
            ,
            "getRestaurantMenuImages").then((results) =>
                results?.json().then((obj1) => {
                    if (results.status == 200 || results.status == 201) {
                        if (obj1.message.length != 0) {
                            setPhotos(obj1.message)
                        }
                        Notiflix.Loading.remove();
                    }
                })
            );
    }

    //  cropper
    let cropperRef = React.createRef();

    const handleImageChange = (e) => {
        if (e.target.files.length > 0) {
            const file = e.target.files[0];
            let filename = `${Math.floor(Math.random() * 10000)}-${file.name}`
            const reader = new FileReader();
            setCroppedImageName(file.name);
            setCropData("#")
            reader.onload = () => {
                setImageDataCover(file)
                setImage(reader.result)
            };
            reader.readAsDataURL(file);
        }
    }
    const handleCrop = () => {
        if (cropperRef.current) {
            const cropper = cropperRef.current.cropper;
            setCropData(cropper.getCroppedCanvas().toDataURL());
            cropper.getCroppedCanvas().toBlob((blob) => {
                setCroppedImage(blob);
            });
        }
    };



    return (
        <>
            <Row>
                <p className="mb-3">
                    Image size should be 1080 x 1080
                </p>
                <Col lg={6}>
                    <div className="large-image h-100 gallery-large-upload">
                        {image == null ?
                            <img src={selectImage ? selectImage : noImage} /> :
                            <>
                                {cropData === '#' ?
                                    <>
                                        <Cropper
                                            style={{ height: 400, width: "100%" }}
                                            ref={cropperRef}
                                            initialAspectRatio={4}
                                            preview=".img-preview"
                                            src={image}
                                            viewMode={1}
                                            aspectRatio={1080 / 1080}
                                            background={false}
                                            checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
                                            guides={true}
                                        />
                                        <Button variant="danger" onClick={handleCrop} className="mt-3">
                                            Crop
                                        </Button>
                                        {/* <input type='file' accept="image/*" style={{ display: "block" }}
                                    onChange={(e) => {

                                        handleImageChange(e)
                                        //     selectImagefile(e.target.files[0])
                                        //     e.preventDefault();
                                        //     const imageFile = e.target.files[0];
                                        //     const form = new FormData();
                                        //     let filename = `${Math.floor(Math.random() * 10000)}-${imageFile.name}` + restaurantId
                                        //     form.append("file", imageFile);
                                        //     form.append("foldername", "restaurant");
                                        //     form.append("schema", schema);
                                        //     form.append("filename", filename);
                                        //     let response;
                                        //     response = fetch(ImageApiUrl, {
                                        //         method: "POST",
                                        //         body: form,
                                        //     }).then(response => response.json())
                                        //         .then(data1 => {
                                        //             PostApiCall.postRequest({
                                        //                 imageid: imageId,
                                        //                 restaurantid: restaurantId,
                                        //                 imagetype: menuTypeId,
                                        //                 imageurl: imageurl + `/${schema}/` + 'restaurant/' + filename.split('.').slice(0, -1).join('.') + '.webp',
                                        //                 status: "",
                                        //             },
                                        //                 `${SaveApiUrl}`).then((results) =>
                                        //                     results?.json().then((obj1) => {
                                        //                         if (results.status == 200 || results.status == 201) {
                                        //                             if (menuTypeName == "gallery images") {
                                        //                                 getRestaurantImages();
                                        //                             } else {
                                        //                                 getRestaurantMenuImages();
                                        //                                 setSelectImage(obj1.message[0].fld_image_url)
                                        //                             }
                                        //                             Notiflix.Loading.remove();
                                        //                         }
                                        //                     })
                                        //                 );
                                        //             Notiflix.Loading.remove()
                                        //             Notiflix.Notify.success("Image successfully uploaded.")
                                        //         })
                                    }
                                    }
                                    src={selectImage}
                                // id={data.fld_id}
                                /> */}
                                    </>
                                    :
                                    <>
                                        <img htmlFor="photo-upload" src={cropData} />
                                        {/* <Button variant="danger" onClick={(e) => { handleImageChange(e) }} className="mt-3 mr-3">
                                            Change
                                        </Button> */}
                                        <Button variant="danger" onClick={(e) => {
                                            // selectImagefile(e.target.files[0])
                                            Notiflix.Loading.dots("...")
                                            e.preventDefault();
                                            const form = new FormData();
                                            let filename = `${Math.floor(Math.random() * 10000)}-${croppedImageName}` + restaurantId
                                            form.append("file", croppedImage);
                                            form.append("foldername", "restaurant");
                                            form.append("schema", schema);
                                            form.append("filename", filename);
                                            let response;
                                            response = fetch(ImageApiUrl, {
                                                method: "POST",
                                                body: form,
                                            }).then(response => response.json())
                                                .then(data1 => {
                                                    PostApiCall.postRequest({
                                                        imageid: imageId,
                                                        restaurantid: restaurantId,
                                                        imagetype: menuTypeId,
                                                        imageurl: imageurl + `/${schema}/` + 'restaurant/' + filename.split('.').slice(0, -1).join('.') + '.webp',
                                                        status: "",
                                                    },
                                                        `${SaveApiUrl}`).then((results) =>
                                                            results?.json().then((obj1) => {
                                                                if (results.status == 200 || results.status == 201) {
                                                                    if (menuTypeName == "gallery images") {
                                                                        getRestaurantImages();
                                                                    } else {
                                                                        getRestaurantMenuImages();
                                                                        setSelectImage(obj1.message[0].fld_image_url)
                                                                    }
                                                                    setImage(null)
                                                                    setCropData("#")
                                                                    Notiflix.Loading.remove();
                                                                }
                                                            })
                                                        );
                                                    Notiflix.Loading.remove()
                                                    Notiflix.Notify.success("Image successfully uploaded.")
                                                })
                                        }} className="mt-3">
                                            Save
                                        </Button>
                                    </>
                                }
                            </>
                        }

                    </div>
                </Col>
                <Col lg={6} className="pl-0" style={{
                    height: "370px",
                    overflowY: "auto"
                }}>
                    <Row>
                        {photos.length >= 25 ? "" :
                            <ImgUploadNew
                                onChange={async (e) => {
                                    if (menuTypeName == "gallery images") {
                                        handleImageChange(e)
                                    }
                                    else {
                                        e.preventDefault();
                                        const imageFile = e.target.files[0];
                                        if (imageFile.name != undefined) {
                                            const form = new FormData();
                                            let filename = `${Math.floor(
                                                Math.random() * 10000
                                            )}-${imageFile.name}` + restaurantId
                                            let path = `${Math.floor(
                                                Math.random() * 10000
                                            )}-${imageFile.name}`;
                                            form.append("file", imageFile);
                                            form.append("foldername", "restaurant");
                                            form.append("schema", schema);
                                            form.append("filename", filename);
                                            Notiflix.Loading.dots();
                                            const res = await fetch(ImageApiUrl,
                                                { method: "POST", body: form }
                                            );
                                            const response = await res.json();

                                            if (response) {
                                                PostApiCall.postRequest({
                                                    restaurantid: restaurantId,
                                                    imagetype: menuTypeId,
                                                    imageurl: imageurl + `/${schema}/` + 'restaurant/' + filename.split('.').slice(0, -1).join('.') + '.webp',
                                                    status: "",
                                                },
                                                    `${SaveApiUrl}`).then((results) =>
                                                        results?.json().then((obj1) => {
                                                            if (results.status == 200 || results.status == 201) {
                                                                if (menuTypeName == "gallery images") {
                                                                    getRestaurantImages();
                                                                } else {
                                                                    getRestaurantMenuImages();
                                                                }
                                                                Notiflix.Loading.remove();
                                                            }
                                                        })
                                                    );
                                                Notiflix.Loading.remove();
                                            }
                                        }
                                    }
                                }
                                }
                                src={noImage}
                            />
                        }
                        {photos.length > 0 ? photos.map((data) => (
                            <>
                                <Col lg={3} className="mb-3">
                                    <div className="popup-small-images">
                                        <button
                                            className="img-delete-btn"
                                            onClick={() => {
                                                DeleteImage(data.fld_id, menuTypeName == "gallery images" ? "RestaurantImages" : "RestaurantMenuImages")
                                            }}
                                        ><MdOutlineCancel />
                                        </button>
                                        <img
                                            src={data.fld_image_url}
                                            id={data.id}
                                            style={{ width: "100%", height: "100%", borderRadius: "5%", objectFit: "cover", cursor: "pointer" }}
                                            onClick={() => {
                                                setSelectImage(data.fld_image_url);
                                                setimageId(data.fld_id)
                                            }}
                                        />
                                    </div>
                                </Col>
                            </>
                        )) : ""}
                    </Row>
                </Col>
            </Row>
        </>
    );
};
export default ImageUpload;
