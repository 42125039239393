// var urlString = "https://manage.bechofy.in/flosil-api/";
// var urlString = "http://localhost:3012/api-grubwebsite/";
var urlString = "https://www.grubdigest.com/api-grubwebsite/";

const PostApiCall = {
  postRequest(userData, url) {
    return fetch(urlString + url, {
      method: "POST",
      headers: {
        Accept: "application/json",
        "Content-Type": "application/json",
        schema: "grubdigest_usr",
      },
      body: JSON.stringify(userData),
    })
      .then((response) => {
        return response;
      })
      .catch((error) => {
        console.log("request failed", error);
        return error;
      });
  },
};

export default PostApiCall;

// var urlString = "http://grubdigest.com/api-grub/";
// const PostApiCall = {
//   postRequest(userData, url) {

//     return fetch(urlString + url, {
//       method: "POST",
//       headers: {
//         Accept: "application/json",
//         "Access-Control-Allow-Origin": "*",
//         "Access-Control-Allow-Headers": "*",
//         "Content-Type": "application/json",
//       },
//       body: JSON.stringify(userData),
//     })
//       .then((response) => {
//         return response;
//       })
//       .catch((error) => {

//         return error;
//       });
//   },
// };

// export default PostApiCall;
