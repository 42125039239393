import Notiflix from "notiflix";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import PostApiCall from "../../API/POSTAPI";
import SAVEDM from "../../API/SAVEDM";
import ChefHatIcon from "../../assets/images/chef-hat.png";
import ScanMe from "../../assets/images/scan-me.png";
import "./DigitalMenuQr.css";

const DigitalMenuQr = () => {
  const [qrCodeUrl, setQrCodeUrl] = useState("");
  const [showButton, setShowButton] = useState("");
  const [restaurantName, setRestaurantName] = useState("");
  const [qrDownloadURL, setQRDownloadURL] = useState("");
  let params = useParams();

  useEffect(() => {
    Notiflix.Loading.dots();
    PostApiCall.postRequest(
      {
        clientid: params.id,
      },
      "getDigitalMenuQrCode"
    ).then((resultdes) =>
      resultdes.json().then((obj) => {
        if (obj.message != 0) {
          setQrCodeUrl(obj.message[0].fld_qr_code);
          setRestaurantName(obj.message[0].fld_restaurantname);
          setShowButton(params.showbutton)
          setQRDownloadURL(obj.message[0].fld_digital_qr)

        } else {
          Notiflix.Loading.remove();
        }
      })
    );
    Notiflix.Loading.remove();
  }, []);

  function downloadQRCodePdf() {
    if (qrDownloadURL !== "" && qrDownloadURL != null && qrDownloadURL != undefined) {
      window.location.href = qrDownloadURL

    } else {
      Notiflix.Loading.dots();
      SAVEDM.postRequest(
        {
          url: window.location.href + "/no",
          imagename: restaurantName + "-" + params.id + "QR-CODE.pdf",
          restaurantid: params.id
        },
        "htmltopdf"
      ).then((resultdes) =>
        resultdes.json().then((obj) => {
          Notiflix.Loading.remove();
          window.location.href = obj.message
        })
      );
    }

  }

  return (
    <>
      <div className="qr-div container" id="digitalqr">
        <div className="row align-items-center justify-content-center">
          <div className="col-12 text-center mt-4">
            <div className="d-flex align-items-center justify-content-center">
              <img src={ChefHatIcon} className="img-fluid chef-icon" />
              {/* <div className="col-6"> */}
              <h1 className="fw-bold mb-0 ms-3 ms-lg-5">{restaurantName}</h1>
            </div>
            <h2 className="playlist-font mt-2 ms-lg-5">Digital Menu</h2>
          </div>
          <div className="qr-bg col-12 mt-3 mt-lg-4">
            <img src={ScanMe} className="qr-frame" />
            <img src={qrCodeUrl} />
          </div>
        </div>
      </div>
      {showButton !== "no" ?
        <div className="col-12 text-center mt-4">
          <button
            type="button"
            onClick={() => {
              downloadQRCodePdf();
            }}
            className="btn btn-danger ms-lg-5">
            Download QR-Code
          </button>
        </div> : ""}
      {/* <div className="w-100 text-center text-lg-start p-3">
        <p>Powered by</p>
        <img src={GrubDigest} className="img-fluid footer-logo" />
      </div>
      <footer>
        <Container className="px-lg-1 mb-0">
          <Row className="justify-content-lg-center justify-content-center">
            <Col lg={6} className="">
              <p className="design text-lg-end text-end">
                <p class="text-center mb-lg-0 mb-0 text-white">
                  <a href="" target="_blank">
                    www.restaurantname.com
                  </a>
                </p>
              </p>
            </Col>
          </Row>
        </Container>
      </footer> */}
    </>
  );
};

export default DigitalMenuQr;
