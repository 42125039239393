import Notiflix from "notiflix";
import React, { useEffect, useState } from "react";
import { Button, Container, FloatingLabel, Form, Row, Col } from "react-bootstrap";
import Carousel from "react-multi-carousel";
import "react-multi-carousel/lib/styles.css";
import GetApiCall from "../../API/GETAPI";
import PostApiCall from "../../API/POSTAPI";
import ThankyouOffcanvas from "../../component/ThankyouOffcanvas/ThankyouOffcanvas";
import ContactUsImageFree from "../../assets/images/contact-us/grub-digest-contact-1.png";
import ContactUsImageOnline from "../../assets/images/contact-us/grub-digest-contact-2.png";
import ContactUsImageDigital from "../../assets/images/contact-us/grub-digest-contact-3.png";
import ContactUsImagePhoto from "../../assets/images/contact-us/grub-digest-contact-4.png";
import OnbaordingImage from "../../assets/images/OnbaordingImage.png";
import { useContext } from "react";
import { store } from "../../Context/store";

function OnBoardingLandingPage() {
    const [scroll, setScroll] = useState(false);
    const [show, setShow] = useState(false);
    const [showFormModal, setShowFormModal] = useState(false);
    // const handleFormShow = () => setShowFormModal(!showFormModal);
    const [contactPerson, setContactPerson] = useState("");
    const [restaurantName, setRestaurantName] = useState("");
    const [address, setAddress] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [email, setEmail] = useState("");
    const [city, setCity] = useState("");
    const [state, setState] = useState("");
    const [country, setCountry] = useState("");
    const [countries, setCountries] = useState([]);
    const [states, setStates] = useState([]);
    const [cities, setCities] = useState([]);
    const [showThankyouModal, setShowThankyouModal] = useState(false);
    const [checkEmail, setCheckEmail] = useState("");
    const { homeVisited, setHomeVisited } = useContext(store)
    const responsive = {
        superLargeDesktop: {
            breakpoint: { max: 4000, min: 3000 },
            items: 2,
        },
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 4,
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
        },
    };
    useEffect(() => {
        if (sessionStorage.getItem("HomepageVisited") === "true") {
            setHomeVisited(false)
        } else {
            if (homeVisited) {
                handleFormShow();
            }
        }
    }, [homeVisited])
    useEffect(() => {
        if (window.pageYOffset > 0) {
            setScroll("scroll");
        } else {
            window.addEventListener("scroll", () => {
                setScroll(window.scrollY > 0);
            });
        }
        if (window.pageYOffset > 0) {
            setScroll("scrollSticky");
        } else {
            window.addEventListener("scrollSticky", () => {
                setScroll(window.scrollY > 0);
            });
        }
        GetApiCall.getRequest("getCountry").then((results) =>
            results?.json().then((obj) => {
                if (results.status == 200 || results.status == 201) {
                    setCountries(obj.message);
                }
            })
        );
        // setModalShow(true)
    }, []);
    const handleFormShow = () => setShowFormModal(true);
    const handleFormHide = () => {
        sessionStorage.setItem("HomepageVisited", "true")
        setHomeVisited(false)
        setShowFormModal(false)
    };

    function getStates(country) {
        PostApiCall.postRequest({ countryid: country }, "getState").then(
            (resultcategory) =>
                resultcategory.json().then((obj) => {
                    if (resultcategory.status == 200 || resultcategory.status == 201) {
                        setStates(obj.message);
                    }
                })
        );
    }




    function getCities(state) {
        PostApiCall.postRequest({ stateid: state }, "getCity").then(
            (resultcategory) =>
                resultcategory.json().then((obj) => {
                    if (resultcategory.status == 200 || resultcategory.status == 201) {
                        setCities(obj.message);
                    }
                })
        );
    }
    return (
        <Container fluid>
            <Row>
                <div className="col-12 text-center mb-4">
                    <h3 className="mb-2 fw-bold">
                        Get an Accelerated Traction for your Food Business
                    </h3>
                    <h6 className="mb-2">
                        We have you covered in all aspects to get your Food Business to
                        the next level. Get in touch with us to drive your business ahead.
                    </h6>
                </div>
            </Row>
            <Carousel
                swipeable={true}
                draggable={true}
                showDots={false}
                responsive={responsive}
                ssr={true} // means to render carousel on server-side.
                infinite={false}
                keyBoardControl={true}
                containerClass="carousel-container"
                dotListClass="custom-dot-list-style"
                itemClass="pb-4"
            >
                <div className="mb-4 mx-2">
                    <img
                        src={ContactUsImageFree}
                        className="img-fluid rounded"
                        alt=""
                    />
                </div>
                <div className="mb-4 mx-2">
                    <img
                        src={ContactUsImageOnline}
                        className="img-fluid rounded"
                        alt=""
                    />
                </div>
                <div className="mb-4 mx-2">
                    <img
                        src={ContactUsImageDigital}
                        className="img-fluid rounded"
                        alt=""
                    />
                </div>
                <div className="mb-4 mx-2">
                    <img
                        src={ContactUsImagePhoto}
                        className="img-fluid rounded"
                        alt=""
                    />
                </div>
            </Carousel>

            <div className="row justify-content-center">
                <div className="col-lg-6 contact-form margin-bottom-3">
                    <div class="section-head col-sm-12 px-lg-0">
                        <h4>
                            <span>Add your</span>
                            <br />
                            Food Business to Grub Digest.
                        </h4>
                    </div>
                    <div className="card p-3 shadow-lg mx-lg-2 mx-3">
                        <Form className="row Onboarding-Form">
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Contact Person *"
                                className="mb-3 col-12 col-lg-6">
                                <Form.Control
                                    type="text"
                                    placeholder="Contact Person"
                                    onChange={(e) => {
                                        setContactPerson(e.target.value);
                                    }}
                                />
                            </FloatingLabel>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Restaurant Name *"
                                className="mb-3 col-12 col-lg-6">
                                <Form.Control
                                    type="text"
                                    placeholder="Restaurant Name"
                                    onChange={(e) => {
                                        setRestaurantName(e.target.value);
                                    }}
                                />
                            </FloatingLabel>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Contact Number *"
                                className="mb-3 col-12 col-lg-6">
                                <Form.Control
                                    type="text"
                                    placeholder="Contact Number"
                                    maxLength={10}
                                    onChange={(e) => {
                                        setMobileNumber(e.target.value);
                                    }}
                                />
                            </FloatingLabel>
                            <FloatingLabel
                                controlId="floatingInput"
                                label="Email *"
                                className="mb-3 col-12 col-lg-6">
                                <Form.Control
                                    type="text"
                                    placeholder="Email"
                                    onChange={(e) => {
                                        setEmail(e.target.value);
                                    }}
                                    onBlur={(e) => {
                                        PostApiCall.postRequest({ emailAddress: e.target.value }, "getEmailAvailability").then(
                                            (resultcategory) =>
                                                resultcategory.json().then((obj) => {
                                                    if (resultcategory.status == 200 || resultcategory.status == 201) {
                                                        if (obj.message[0].emailcount > 0) {
                                                            setCheckEmail("No")
                                                        } else {
                                                            setCheckEmail("Yes")
                                                        }
                                                    }
                                                })
                                        );
                                    }}
                                />
                                {email != "" ?
                                    <div
                                        className="">
                                        {checkEmail == "No" ?
                                            <p className="text-danger">Email already registered.</p>
                                            :
                                            <p className="text-success">Email address available.</p>
                                        }
                                    </div> : <div
                                        className="mb-3"></div>
                                }
                            </FloatingLabel>

                            <FloatingLabel
                                controlId="floatingInput"
                                label="Location *"
                                className="mb-3 col-12 col-lg-6">
                                <Form.Control
                                    type="text"
                                    placeholder="Location"
                                    onChange={(e) => {
                                        setAddress(e.target.value);
                                    }}
                                />
                            </FloatingLabel>

                            <FloatingLabel
                                className="col-12 col-lg-6"
                                controlId="floatingSelect"
                                label="Country *">
                                <Form.Select
                                    aria-label="Country"
                                    value={country}
                                    onChange={(e) => {
                                        setCountry(e.target.value);
                                        PostApiCall.postRequest(
                                            { countryid: e.target.value },
                                            "getState"
                                        ).then((resultcategory) =>
                                            resultcategory.json().then((obj) => {
                                                if (
                                                    resultcategory.status == 200 ||
                                                    resultcategory.status == 201
                                                ) {
                                                    setStates(obj.message);
                                                }
                                            })
                                        );
                                    }}>
                                    <option>Select Country</option>
                                    {countries.length > 0 ? (
                                        countries
                                            .filter((data) => {
                                                if (data.value == 101) {
                                                    return data;
                                                }
                                            })
                                            .map((country, i) => {
                                                return (
                                                    <option value={country.value}>{country.label}</option>
                                                );
                                            })
                                    ) : (
                                        <></>
                                    )}
                                </Form.Select>
                            </FloatingLabel>

                            <FloatingLabel
                                className="mt-3 w-100 col-12 col-lg-6"
                                controlId="floatingSelect"
                                label="State *">
                                <Form.Select
                                    aria-label="State"
                                    value={state}
                                    onChange={(e) => {
                                        setState(e.target.value);
                                        getCities(e.target.value);
                                    }}>
                                    <option>Select State</option>
                                    {states.length > 0 ? (
                                        states.map((state, i) => {
                                            return <option value={state.value}>{state.label}</option>;
                                        })
                                    ) : (
                                        <></>
                                    )}
                                </Form.Select>
                            </FloatingLabel>

                            <FloatingLabel
                                className="mt-3 w-100 col-12 col-lg-6"
                                controlId="floatingSelect"
                                label="City *">
                                <Form.Select
                                    aria-label="City"
                                    value={city}
                                    onChange={(e) => {
                                        setCity(e.target.value);
                                    }}>
                                    <option>Select City</option>
                                    {cities.length > 0 ? (
                                        cities.map((city, i) => {
                                            return <option value={city.value}>{city.label}</option>;
                                        })
                                    ) : (
                                        <></>
                                    )}
                                </Form.Select>
                            </FloatingLabel>
                            <div className="d-flex justify-content-end align-items-center mt-3">
                                {checkEmail == "Yes" ?
                                    <Button
                                        onClick={() => {
                                            if (contactPerson != "") {
                                                if (restaurantName != "") {
                                                    if (mobileNumber != "") {
                                                        if (mobileNumber.length == 10) {
                                                            if (email != "") {
                                                                if (
                                                                    /^(([^<>()\\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/.test(
                                                                        email
                                                                    )
                                                                ) {
                                                                    if (address != "") {
                                                                        if (country != "") {
                                                                            if (state != "") {
                                                                                if (city != "") {
                                                                                    Notiflix.Loading.dots("Please Wait")
                                                                                    PostApiCall.postRequest(
                                                                                        {
                                                                                            contactPerson: contactPerson,
                                                                                            companyName: restaurantName,
                                                                                            address: address,
                                                                                            country: country,
                                                                                            state: state,
                                                                                            city: city,
                                                                                            mobileNumber: mobileNumber,
                                                                                            email: email,
                                                                                        },
                                                                                        "addRestaurant"
                                                                                    ).then((results) =>
                                                                                        results?.json().then((obj1) => {
                                                                                            if (
                                                                                                results.status == 200 ||
                                                                                                results.status == 201
                                                                                            ) {
                                                                                                if (obj1.message.length != 0) {
                                                                                                    localStorage.setItem(
                                                                                                        "restaurantid",
                                                                                                        obj1.message[0].resturantid
                                                                                                    );
                                                                                                    setCountry("");
                                                                                                    setState("");
                                                                                                    setCity("");
                                                                                                    setRestaurantName("");
                                                                                                    setContactPerson("");
                                                                                                    setAddress("");
                                                                                                    setEmail("");
                                                                                                    setMobileNumber("");
                                                                                                }
                                                                                                Notiflix.Loading.remove();
                                                                                                setShowThankyouModal(true);
                                                                                                handleFormHide();
                                                                                            }
                                                                                        })
                                                                                    );
                                                                                }
                                                                                else {
                                                                                    Notiflix.Notify.failure("Select City");
                                                                                }
                                                                            } else {
                                                                                Notiflix.Notify.failure("Select State");
                                                                            }
                                                                        } else {
                                                                            Notiflix.Notify.failure("Select Country");
                                                                        }
                                                                    } else {
                                                                        Notiflix.Notify.failure("Enter Location");
                                                                    }
                                                                } else {
                                                                    Notiflix.Notify.failure("Enter Valid Email");
                                                                }
                                                            } else {
                                                                Notiflix.Notify.failure("Enter Email");
                                                            }
                                                        } else {
                                                            Notiflix.Notify.failure("Enter Valid Contact Number");
                                                        }
                                                    } else {
                                                        Notiflix.Notify.failure("Enter Contact Number");
                                                    }
                                                } else {
                                                    Notiflix.Notify.failure("Enter Restaurant Name");
                                                }
                                            } else {
                                                Notiflix.Notify.failure("Enter Contact Person.");
                                            }
                                        }}
                                        className="btn btn-danger w-50"
                                    >
                                        Continue
                                    </Button>
                                    : <Button className="btn btn-danger w-50" disabled={true}
                                    >
                                        Continue
                                    </Button>}
                            </div>

                        </Form>
                    </div>
                </div>
                <div className="col-lg-6 text-center contact-block d-none d-lg-block">
                    {/* <div id="ieatmaps"></div> */}
                    <div className="row flex-column">
                        <div className="col-lg-12">
                            <img src={OnbaordingImage} className="contact-image" alt="" />
                        </div>
                    </div>
                </div>
            </div>
            <ThankyouOffcanvas showOffcanvas={showThankyouModal} setShowThankyouModal={setShowThankyouModal} email={email} />
        </Container>
    );
}

export default OnBoardingLandingPage;
