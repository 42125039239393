import { createContext, useState } from "react";

export const store = createContext();

const Provider = ({ children }) => {
  const [chooseCity, setChooseCity] = useState(
    localStorage.getItem("choose_city")
  );
  const [homeVisited, setHomeVisited] = useState(false)

  return (
    <store.Provider value={{ chooseCity, setChooseCity, homeVisited, setHomeVisited }}>
      {children}
    </store.Provider>
  );
};

export default Provider;
